<template>
  <div class="ad-steps-container">
    <leftStepVue />
    <div class="right-steps-wrapper">
      <div class="right-steps-content">
        <a-spin :spinning="isLoading" style="width: 100%; height: 100%; box-sizing: border-box">
          <StepAdPlan ref="StepAdPlan" />
          <StepAdGroup v-if="steps > 1" ref="StepAdGroup" />
        </a-spin>
      </div>
      <div class="right-steps-footer">
        <a-button @click="handleCencel">取消</a-button>
        <!-- 非选择已有按钮 -->
        <span v-if="!isSelect">
          <!-- 添加按钮 -->
          <span v-if="isAdd">
            <a-button @click="onlyAdd" v-if="steps === 1 && isAdd">仅创建账号</a-button>
            <a-button type="primary" @click="handleSubmitAndNext">保存{{ steps === 1 ? '并继续' : '' }}</a-button>
          </span>
          <!-- 编辑按钮 -->
          <span v-else>
            <a-button type="primary" v-if="steps === 1" @click="UpdateNext">下一步</a-button>
            <a-button type="primary" @click="UpdateSubmit">保存</a-button>
          </span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import leftStepVue from './components/leftStep'
import StepAdPlan from './components/stepAdPlan'
import StepAdGroup from './components/stepAdGroup'
export default {
  components: {
    leftStepVue,
    StepAdPlan,
    StepAdGroup
    // StepAdCreative
  },
  provide () {
    return {
      changeLoading: (sts) => {
        this.isLoading = sts
      },
      isAdmin: (sts) => {
        this.isAdmin = sts
      }
    }
  },
  data () {
    return {
      steps: 1,
      isLoading: false,
      isAdd: false,
      isSelect: false,
      isAdmin: undefined,
      userId: undefined
    }
  },
  mounted () {},
  watch: {
    $route: {
      handler (val) {
        this.steps = val && val.query && +val.query.steps
        this.isAdd = val.query.type === 'add'
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    // 仅创建账户
    async onlyAdd () {
      const resp = await this.handleSubmit()
      if (resp.code && resp.code !== 200) {
        return
      }
      this.$router.push({
        path: '/accountInfo/accountManage'
      })
    },
    // UpdateNext
    UpdateNext () {
      this.$router.push({
        path: `/accountInfo/addAccountManage?type=edit`,
        query: {
          steps: 2,
          isAdmin: this.isAdmin,
          userId: this.$route.query.id
        }
      })
    },
    // isAdd 保存并继续
    async handleSubmitAndNext () {
      const resp = await this.handleSubmit()
      if (resp.code && resp.code !== 200) {
        return
      }
      //  下一步骤
      this.handleNext()
    },
    // isAdd 保存
    async UpdateSubmit () {
      const resp = await this.handleSubmit()
      if (resp.code && resp.code !== 200) {
        return
      }
      this.$message.success('操作成功！')
      if (this.steps === 1) {
        this.$router.push({
          path: `/accountInfo/addAccountManage?type=edit`,
          query: {
            steps: 2,
            isAdmin: this.isAdmin,
            userId: this.userId
          }
        })
      }
    },
    // 提交方法
    async handleSubmit () {
      if (this.steps === 1) {
        const res = await this.$refs.StepAdPlan.handleSubmitAdPlan()
        this.userId = res.data.id
        return res
      } else if (this.steps === 2) {
        this.$router.push({
          path: '/accountInfo/accountManage'
        })
        const res = await this.$refs.StepAdGroup.handleSubmitAdGroup()
        return res
      }
    },
    // 下一步骤
    handleNext () {
      if (this.steps === 1) {
        this.$router.push({
          path: `/accountInfo/addAccountManage?type=add`,
          query: {
            steps: 2,
            isAdmin: this.isAdmin,
            userId: this.userId,
            type: 'add'
          }
        })
      }
    },
    // 取消
    handleCencel () {
      this.$router.push({
        path: '/accountInfo/accountManage'
      })
    }
  }
}
</script>

<style lang="less" scoped>
.ad-steps-container {
  width: calc(100% - 20px);
  // min-width: 1280px;
  margin: 0 auto;
  min-height: 84vh;
  display: flex;
  box-sizing: border-box;
  .right-steps-wrapper {
    flex-grow: 1;
    width: 0;
    border-radius: 10px;
    background: #fff;
    display: flex;
    flex-direction: column;
    .right-steps-content {
      padding: 20px 50px;
      flex-grow: 1;
      height: 0;
      position: relative;
      box-sizing: border-box;
      overflow-y: auto;
      overflow-x: auto;
    }
    .right-steps-footer {
      height: 60px;
      margin-top: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-top: 1px solid #ddd;
      button {
        margin-right: 10px;
      }
    }
  }
}
</style>
