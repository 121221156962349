<template>
  <div id="step_ad_group" class="step-ad-group-container">
    <AdPlanViewVue v-if="+steps === 2" :adPlanInfo="selectPlanInfo" />
    <a-tabs v-if="+steps === 2" :activeKey="activeKey" :animated="false">
      <a-tab-pane :key="1" tab="账号定制" :force-render="true">
        <AdGroupForm ref="AdGroupForm" />
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
import AdGroupForm from './AdGroupForm'
import AdPlanViewVue from './AdPlanView.vue'
export default {
  inject: [],
  components: { AdGroupForm, AdPlanViewVue },
  provide () {
    return {
      selectPlanInfo: (sts) => {
        this.selectPlanInfo = sts
      }
    }
  },
  data () {
    return {
      // tab值
      activeKey: 1,
      // 当前步骤
      steps: 1,
      // 是否是添加
      isAdd: false,
      selectPlanInfo: {}
    }
  },
  watch: {
    $route: {
      handler (val) {
        // 获取当前步骤
        this.steps = val && val.query && +val.query.steps
        // 刷新页面处理
        // 第二步，显示form表单
        this.steps && this.steps === 2 && (this.activeKey = 1)
        // 获取当前是否是添加状态
        this.isAdd = val.query.type === 'add'
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    // 调用子组件form表单的提交方法
    handleSubmitAdGroup () {
      return new Promise(async (resolve) => {
        const res = await this.$refs.AdGroupForm.handleSubmitAdGroup()
        // 抛出给父级组件
        resolve(res)
      })
    }
  }
}
</script>

<style lang="less" scoped>
.step-ad-group-container {
  .select_group {
    display: flex;
    height: 40px;
    line-height: 36px;
    margin: 30px 0;
  }
}
</style>
