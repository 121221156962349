<template>
  <div class="rtb-config-container">
    <div>
      <a-popover placement="bottom" trigger="hover" overlayClassName="appItem-container">
        <template #content>
          <div class="popover-wrapper">
            <el-table
              row-key="id"
              :max-height="tableHeight"
              :data="pagedData"
              style="width: 100%"
              ref="multipleTable"
              border
            >
              <el-table-column prop="createTime" label="创建时间" align="center"></el-table-column>
              <el-table-column prop="name" label="应用" width="220" align="center">
                <template slot-scope="scope">
                  <div class="appItem" @click="goToApp(scope.row.id)">
                    <div class="img">
                      <img
                        :src="scope.row.iconUrl ? getCreativeUrl(scope.row.iconUrl) : '/images/app-none.png'"
                        alt=""
                      />
                    </div>
                    <div class="desc">
                      <span class="name">
                        <IosSvg v-if="scope.row.os === 0" class="svg-icon" />
                        <AndroidSvg v-else class="svg-icon" />{{ scope.row.name }}
                      </span>
                      <div class="id" style="font-size: 12px; line-height: 20px">
                        ID:{{ scope.row.id }}
                        <a-tooltip placement="bottom">
                          <template slot="title">
                            <span style="width: 100%">复制应用ID</span>
                          </template>
                          <a-icon
                            @click.stop="copyId(scope.row.id)"
                            style="cursor: pointer; margin: 3px 5px 0 0"
                            type="copy"
                          />
                        </a-tooltip>
                      </div>
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="adPlaceNum" label="广告位个数" width="100" align="center">
                <template slot-scope="scope">
                  <div style="cursor: pointer" @click="goToPlace(scope.row.id)">{{ scope.row.adPlaceNum }}</div>
                </template>
              </el-table-column>
              <!-- <div slot="empty" style="padding: 10px 15px 0 15px">
                <m-empty imgHeight="100px">
                  <template #description> 暂无数据 </template>
                </m-empty>
              </div> -->
            </el-table>
            <!-- <a-pagination
              class="pagination"
              v-if="total > 0"
              show-size-changer
              :pageSize="limit"
              @change="handleChangePage"
              @showSizeChange="onShowSizeChange"
              :current="page"
              :total="total"
              :show-total="(total) => `共 ${total} 条`"
            /> -->
          </div>
        </template>
        <div style="text-align: center">
          <a-button size="small" type="link" v-if="Number(data.isAdmin) === 1" style="cursor: pointer">{{
            data.createAppResponseVoList.length
          }}</a-button>
          <a-button size="small" type="link" v-else style="cursor: pointer">{{
            data.appResponseVoList.length
          }}</a-button>
        </div>
        <!-- <a-button size="small" type="link" style="text-align: left; margin: 0; padding-left: 0"></a-button> -->
      </a-popover>
    </div>
  </div>
</template>

<script>
import { copy } from '@/utils/string'
import { mapState } from 'vuex'
import IosSvg from '@/assets/icons/ios.svg?inline'
import AndroidSvg from '@/assets/icons/android.svg?inline'
export default {
  name: 'HoverChild',
  components: { IosSvg, AndroidSvg },
  data () {
    return {
      page: 1,
      limit: 10
    }
  },
  props: {
    data: {
      default: () => ({}),
      type: Object
    }
  },
  computed: {
    ...mapState({
      resourceUrl: (state) => state.autoweb.resourceUrl
    }),
    pagedData () {
      const xitem = Number(this.data.isAdmin) === 1 ? this.data.createAppResponseVoList : this.data.appResponseVoList
      // const { page, limit } = this
      // const startIndex = (page - 1) * limit
      // const endIndex = startIndex + limit - 1
      // return xitem.slice(startIndex, endIndex)
      return xitem
    },
    total () {
      const item = Number(this.data.isAdmin) === 1 ? this.data.createAppResponseVoList : this.data.appResponseVoList
      return item.length
    },
    tableHeight () {
      if (this.total > 1) {
        return '320px'
      } else {
        return undefined
      }
    }
  },
  created () {},
  methods: {
    // 复制方法
    copyId (id) {
      const result = copy(id)
      if (result === false) {
        this.$message.error('不支持复制')
      } else {
        this.$message.success('复制成功')
      }
    },
    // 处理应用图标
    getCreativeUrl (url) {
      if (url) {
        return this.resourceUrl + url
      } else {
        return null
      }
    },
    // 跳转到广告页面
    goToPlace (id) {
      this.$router.push({
        path: `/media/adplace?target=adplacen&appId=${id}`
      })
    },
    // 跳转到应用页面
    goToApp (id) {
      this.$router.push({
        path: '/media/app',
        query: {
          appId: id
        }
      })
    },
    // 点击页码
    handleChangePage (page) {
      this.page = page
    },
    // 分页
    onShowSizeChange (current, size) {
      this.page = 1
      this.limit = size
    }
  }
}
</script>

<style lang="less">
.appItem-container {
  width: 550px;
  .popover-wrapper {
    border-radius: 5px;
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .appItem {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    .img {
      width: 40px;
      height: 40px;
      margin-right: 10px;

      img {
        width: 100%;
        height: 100%;
        border-radius: 10px;
      }
    }
    .desc {
      margin-left: 4px;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      position: relative;
      .name {
        text-align: left;
        font-size: 14px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block;
        .svg-icon {
          margin: 0 3px -1px 0;
          height: 13px;
          width: 13px;
        }
      }
      .id {
        flex-grow: 1;
        line-height: 14px;
        font-size: 12px;
        display: flex;
        justify-content: space-between;
      }
    }
    .id {
      font-size: 13px;
      line-height: 20px;
      color: #999;
    }
  }
  .pagination {
    margin: 20px;
  }
}
</style>
