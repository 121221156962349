<template>
  <div class="error-diagnose-container">
    <div class="error-diagnose-left">
      <span class="menu_title">异常来源</span>
      <!-- <a-menu mode="inline" v-model="selectedKeys" type="inner" @select="selectMenuItem">
        <a-menu-item key="third">
          <span> 三方广告平台 </span>
        </a-menu-item>
        <a-menu-item key="media">
          <span> Mediatom平台 </span>
        </a-menu-item>
      </a-menu> -->
      <a-radio-group v-model="selectedKeys" @change="selectMenuItem">
        <a-radio-button class="radioIsadmin" v-for="(item, inx) in radioArr" :key="inx" :value="item.value">
          {{ item.name }}
        </a-radio-button>
      </a-radio-group>
    </div>
    <div class="error-diagnose-right">
      <DiagnoseSearch @handleAutoSearch="handleSearch" />
      <DiagnoseTable ref="table" @handleAutoSearch="handleSearch" />
    </div>
  </div>
</template>

<script>
import DiagnoseSearch from './DiagnoseSearch'
import DiagnoseTable from './DiagnoseTable'
export default {
  components: { DiagnoseSearch, DiagnoseTable },
  data () {
    return {
      selectedKeys: 'third',
      searchquery: {
        type: 1
      },
      radioArr: [{ name: '三方广告平台', value: 'third' }]
    }
  },
  methods: {
    selectMenuItem (item) {
      this.searchquery.type = item.target.value === 'third' ? 1 : 2
      this.handleSearch(this.searchquery)
    },
    handleSearch (query) {
      this.searchquery = { ...this.searchquery, ...query }
      this.$nextTick(() => {
        this.$refs.table.dealquery(this.searchquery)
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/assets/less/mixins/redefineAnt.less';
.error-diagnose-container {
  min-height: 90vh;
  padding: 20px;
  margin: 0 10px;
  border-radius: 5px;
  background: #fff;
  .error-diagnose-left {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    .menu_title {
      width: 70px;
      font-size: 15px;
      line-height: 32px;
      font-weight: 500;
    }
  }
}
.aRadioBtn();
.radioIsadmin {
  border-radius: 5px !important;
}
// ::v-deep.error-diagnose-left{
//   .radioIsadmin.ant-radio-button-wrapper.ant-radio-button-wrapper-checked{
//       background-color: #dde8fc;
//   }
// }
::v-deep.error-diagnose-left .ant-menu.ant-menu-inline {
  border-radius: 0 0 10px 10px;
  border: 1px solid red;
  .ant-menu-item {
    border-left: 3px solid #fff;
    margin: 0;
  }
  .ant-menu-item.ant-menu-item-selected {
    border-left: 3px solid @primary-color;
  }
  .ant-menu-item::after {
    border-right: none;
  }
}
</style>
