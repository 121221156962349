<template>
  <a-modal v-model="personVisible" :title="title" @onCancel="handleCancel" @ok="handleSubmit" :width="500">
    <a-form-model ref="ruleForm" :model="formquery" :rules="rules" v-bind="layout">
      <template v-if="type === 1">
        <a-form-model-item
          label="管理员"
          prop="createUser"
          :rules="[{ required: true, message: '请选择管理员', trigger: 'change' }]"
        >
          <!-- <a-select
            v-model:value="value"
            show-search
            placeholder="input search text"
            style="width: 200px"
            :default-active-first-option="false"
            :show-arrow="false"
            :filter-option="false"
            :not-found-content="null"
            :options="data"
            @search="handleSearch"
            @change="handleChange"
          ></a-select> -->
          <a-select
            v-model="formquery.createUser"
            allowClear
            show-search
            :filter-option="filterOption"
            placeholder="请选择管理员"
          >
            <a-select-option v-for="item in transferList" :key="item.id" :disabled="item.disabled">
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </template>
      <template v-else>
        <a-form-model-item
          label="备注"
          prop="remark"
          :rules="[{ required: true, message: '请输入备注', trigger: 'blur' }]"
        >
          <a-textarea placeholder="请输入备注" v-model="formquery.remark" :rows="6" />
        </a-form-model-item>
      </template>
    </a-form-model>
  </a-modal>
</template>
<script>
import { transferAdmin, updateAccount } from '@/api/system'
export default {
  data () {
    return {
      layout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 14 }
      },
      formquery: {
        createUser: undefined
      },
      rules: {},
      title: ''
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    id: {
      type: Number,
      default: 1
    },
    transferList: {
      default: () => [],
      type: Array
    },
    type: {
      type: Number,
      default: 1
    },
    recordObj: {
      default: () => {},
      type: Object
    }
  },
  watch: {
    recordObj: {
      handler (val) {
        this.formquery = JSON.parse(JSON.stringify(val))
        if (this.type === 1) {
          this.formquery.createUser = undefined
        } else {
          val.remark = val.remarkList && val.remarkList.join('\n')
        }
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    personVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('modalCancel')
      }
    }
  },
  created () {
    this.title = this.type === 1 ? '绑定' : '备注'
  },
  methods: {
    filterOption (input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    // 取消绑定弹窗
    handleCancel () {
      this.personVisible = false
    },
    // 点击绑定弹窗 确定按钮
    async handleSubmit () {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          const list = {
            1: transferAdmin,
            2: updateAccount
          }
          const { id = '', createUser = '' } = this.formquery
          const obj = {
            id,
            createUser
          }
          if (this.type === 2) {
            this.formquery.remarkList = this.formquery.remark.split('\n')
            delete this.formquery.remark
          } else {
            this.formquery = obj
          }
          const res = await list[this.type](this.formquery)
          if (res.code === 200) {
            this.personVisible = false
            this.$message.success('绑定成功!')
            this.$emit('changeRansferAdmin')
          }
        }
      })
    }
  }
}
</script>
<style></style>
