import request from '@/utils/request'
const api = {
  trafficUserList: '/user/getTrafficUserList',
  getAppListForManage: '/app/list',
  // 广告样式列表
  positionList: '/common/position_list',
  getPlaceList: 'place/list',
  // app查广告位列表 多个
  placeListByAppIds: '/place/listByAppIds',
  // 地区数据
  getAreaList: '/common/area_list',
  // 设备列表
  getBrandList: '/common/brandList',
  // rtb广告平台
  getRtbPlatformList: 'rtbAd/api_platform_list',
  // rtb删除广告源
  deleteRtbItem: '/rtbAd/delete',
  // rtb账户关联
  getRtbPlatAccounts: 'rtbAd/accountsByPlat',
  // 获取账号列表
  getAdminList: `/user/adminList`,
  // 通过账户查广告平台
  getPlatListByuser: '/rtbAd/platformListByUser',
  // 通过账户查应用列表
  getAppListByAdminId: '/app/getAppListByAdminId',
  // 获取group列表
  getGroupList: '/aggregate/groupList',
  // 通过placeid获取group列表
  groupListByAdplace: '/aggregate/groupListByAdplace',
  // 获取广告源列表
  getSourceList: '/aggregate/sourceList',
  // 获取渠道列表
  getChannelList: '/app/channelList',
  // 广告位查询广告源
  getSourceListByAdplace: '/aggregate/sourceListByAdplace',
  // 获取非直投广告平台的广告平台列表
  platformList: '/plat/platformList',
  // 定向设置获取app list
  getDirectionalSettingAppList: '/adPlan/getDirectionalSettingAppList',
  getProdAndpackageList: '/common/packageList',
  getDspList: '/rtbAd/dspList',
  getTrafficUserInfo: '/user/getTrafficUserInfo',
  getBudgetList: '/budget/budgetList',
  // 国家
  getCountryList: '/common/countryList',
  // 省份
  getProvinceList: '/common/provinceList',
  // 地区树形结构
  continentList: 'common/continentList',
  getPublisherListByRole: '/publisher/getPublisherListByRole',
  getTrafficBundleList: '/common/trafficBundleList',
  getPlatPositionList: '/common/platPositionList',
  // 获取账户列表
  getAdminUserList: '/user/adminUserList',
  // 广告主投放任务列表
  getDspTaskList: '/plat/getDspTask',
  // 人群包列表
  getCrowdList: '/common/crowdList',
  // 获取流量广告位列表
  gettTrafficPlaceList: '/trafficPlace/getTrafficPlace'
}
export default api

export function getAppListForManage (data = {
  partnerIdList: [],
  trafficUserIdList: []
}) {
  return request({
    url: api.getAppListForManage,
    method: 'post',
    data
  })
}

export function trafficUserList () {
  return request({
    url: api.trafficUserList,
    method: 'get'
  })
}

export function getPositionList () {
  return request({
    url: api.positionList,
    method: 'get'
  })
}
export function getDspList (data) {
  return request({
    url: api.getDspList,
    method: 'post',
    data
  })
}
export function getPlaceList () {
  return request({
    url: api.getPlaceList,
    method: 'get'
  })
}
export function getPlaceListByAppIds (id) {
  return request({
    url: api.placeListByAppIds,
    method: 'post',
    data: id
  })
}
export function getAreaList () {
  return request({
    url: api.getAreaList,
    method: 'get'
  })
}
export function getBrandList () {
  return request({
    url: api.getBrandList,
    method: 'get'
  })
}
// rtb预算平台
export function getRtbPlatformList () {
  return request({
    url: api.getRtbPlatformList,
    method: 'get'
  })
}
export function getAdminList () {
  return request({
    url: api.getAdminList,
    method: 'get'
  })
}
export function getPlatListByuser () {
  return request({
    url: api.getPlatListByuser,
    method: 'get'
  })
}
export function getAppListByAdminId (id) {
  return request({
    url: api.getAppListByAdminId + '/' + id,
    method: 'get'
  })
}
export function getGroupList () {
  return request({
    url: api.getGroupList,
    method: 'get'
  })
}
export function groupListByAdplace (data) {
  return request({
    url: api.groupListByAdplace,
    method: 'post',
    data
  })
}
export function getSourceList () {
  return request({
    url: api.getSourceList,
    method: 'get'
  })
}
export function getChannelList () {
  return request({
    url: api.getChannelList,
    method: 'get'
  })
}
export function getSourceListByAdplace (data) {
  return request({
    url: api.getSourceListByAdplace,
    method: 'post',
    data
  })
}
export function getDirectionalSettingAppList () {
  return request({
    url: api.getDirectionalSettingAppList,
    method: 'get'
  })
}
export function deleteRtbItem (data) {
  return request({
    url: api.deleteRtbItem,
    method: 'post',
    data
  })
}
export function getRtbPlatAccounts (data) {
  return request({
    url: api.getRtbPlatAccounts,
    method: 'post',
    data
  })
}
export function getProdAndpackageList () {
  return request({
    url: api.getProdAndpackageList,
    method: 'get'
  })
}
export function getTrafficUserInfo (data) {
  return request({
    url: api.getTrafficUserInfo,
    method: 'post',
    data
  })
}
export function getBudgetList (data) {
  return request({
    url: api.getBudgetList,
    method: 'post',
    data
  })
}
export function getCountryList (data) {
  return request({
    url: api.getCountryList,
    method: 'post',
    data
  })
}
export function getProvinceList () {
  return request({
    url: api.getProvinceList,
    method: 'post'
  })
}
export function continentList () {
  return request({
    url: api.continentList,
    method: 'post'
  })
}
export function getPublisherListByRole (data) {
  return request({
    url: api.getPublisherListByRole,
    method: 'post',
    data
  })
}

export function getTrafficBundleList () {
  return request({
    url: api.getTrafficBundleList,
    method: 'get'
  })
}

export function getPlatPositionList () {
  return request({
    url: api.getPlatPositionList,
    method: 'get'
  })
}

export function getAdminUserList () {
  return request({
    url: api.getAdminUserList,
    method: 'get'
  })
}
export function getDspTaskList () {
  return request({
    url: api.getDspTaskList,
    method: 'get'
  })
}

export function getCrowdList () {
  return request({
    url: api.getCrowdList,
    method: 'get'
  })
}

export function gettTrafficPlaceList (data) {
  return request({
    url: api.gettTrafficPlaceList,
    method: 'post',
    data
  })
}
