<template>
  <div>
    <div class="place-tree-form-wrapper-manage">
      <a-form-model-item
        prop="placeIdList"
        label="绑定广告位"
      >
        <div class="config-place-manage">
          <div class="config-place-main-left right-border">
            <div class="search-wrapper">
              <a-input-search v-model.trim="searchKey" placeholder="输入名称/ID搜索"></a-input-search>
            </div>
            <div class="app-list-wrapper">
              <div class="app-item" v-for="app in searchList" :key="app.appId">
                <div class="app-box" @click="changeIsExpand($event, app)">
                  <div class="appBoxWrapper">
                    <a-checkbox
                      :indeterminate="app.indeterminate"
                      :checked="app.checkAll"
                      @click="changeAppChecked($event, app)"
                    />
                    <a-popover placement="bottom" trigger="hover">
                      <template #content>
                        <span>{{ app.appName }}</span>
                      </template>
                      <div class="appName">{{ app.appName }}</div>
                    </a-popover>
                  </div>
                  <Arrow :class="['arrow', ,app.isExpand ? 'arrow_up' : 'arrow_down']" />
                </div>
                <div class="place-list" v-if="app.isExpand">
                  <a-checkbox
                    class="place-checkbox"
                    v-for="place in app.childList"
                    :key="place.placeId"
                    :checked="place.checked"
                    @change="changePlaceChecked($event, app, place)"
                  >
                    <span>{{ place.placeName }}</span>
                  </a-checkbox>
                </div>
              </div>
            </div>
          </div>
          <div class="config-place-main-right">
            <div class="config-place-main-right-header">
              <span>已选{{ configuredList.length }}项</span>
              <a-button class="color" type="link" size="small" @click="handleClearAll">全部清除</a-button>
            </div>
            <div class="check-list">
              <div v-for="(place, index) in configuredList" :key="place.placeId" class="check-item">
                <div class="check-place-name">{{ place.placeName }}</div>
                <a-icon
                  v-if="!+place.configured"
                  @click.stop="handleCirclePlace(index)"
                  type="close"
                  class="close-icon"
                />
                <a-popconfirm
                  v-if="+place.configured"
                  title="确认移除已配置广告位?"
                  ok-text="是"
                  cancel-text="否"
                  @confirm="handleCirclePlace(index)"
                >
                  <a-icon type="close" class="close-icon" />
                </a-popconfirm>
              </div>
              <m-empty v-if="configuredList.length === 0" imgHeight="90px" >
                <template #description> 暂未选择广告位 </template>
              </m-empty>
            </div>
          </div>
        </div>
      </a-form-model-item>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import IosSvg from '@/assets/icons/ios.svg?inline'
import AndroidSvg from '@/assets/icons/android.svg?inline'
import Arrow from '@/assets/icons/arrow.svg?inline'

export default {
  name: 'PlaceTreeForm',
  components: { IosSvg, AndroidSvg, Arrow },
  data () {
    return {
      // 选中广告位对象数组
      configuredList: [],
      // 搜索关键字
      searchKey: undefined,
      formquery: {}
    }
  },
  computed: {
    ...mapState({
      // 角色
      role: (state) => state.user.roles[0],
      title: (state) => state.autoweb.title,
      adminUserId: (state) => state.user.adminUserId,
      currentLoginId: (state) => state.user.id
    }),
    isAdmin () {
      if (this.role === 'SUPER_ADMIN' || +this.adminUserId === 1) {
        return true
      }
      return false
    },
    // 左侧树形列表
    searchList () {
      if (this.searchKey === undefined && !this.searchKey) {
        // 搜索字段为空，返回全部数据
        return this.placeTree
      } else {
        // 字段搜索返回相应的应用和广告位
        let arr = []
        this.placeTree.forEach((item) => {
          if (
            item.appName.includes(this.searchKey) ||
            item.appId.includes(this.searchKey) ||
            item.childList.some((place) => place.placeName.includes(this.searchKey)) ||
            item.childList.some((place) => place.placeId.includes(this.searchKey))
          ) {
            arr = [...arr, item]
          }
        })
        return arr
      }
    }
  },
  props: {
    query: {
      default: () => ({
        name: undefined,
        type: undefined
      }),
      type: Object
    },
    placeTree: {
      default: () => ([]),
      type: Array
    },
    checkedList: {
      default: () => ([]),
      type: Array
    }
  },
  watch: {
    query: {
      handler (val) {
        this.formquery = {
          ...this.formquery,
          ...JSON.parse(JSON.stringify(val))
        }
        this.configuredList = [...this.checkedList]
        this.handleCheck()
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    async getPlaceList () {
    },
    // 删除选中点击事件icon
    handleCirclePlace (index) {
      this.configuredList.splice(index, 1)
      this.handleCheck()
      this.changeQuery()
    },
    // 删掉所有选中
    handleClearAll () {
      this.configuredList = []
      this.handleCheck()
      this.changeQuery()
    },
    /**
     * 广告位多选框点击事件
     * @param {*} e 应用多选框点击事件
     * @param {*} app 应用对象
     * @param {*} place 广告位对象
     */
    changePlaceChecked (e, app, place) {
      // 选中状态
      const checked = e.target.checked
      if (checked) {
        // 选中状态
        this.configuredList = [...this.configuredList, place]
      } else {
        // 未选中状态
        this.configuredList.splice(
          this.configuredList.findIndex((item) => item.placeId === place.placeId),
          1
        )
      }
      this.handleCheck()
      this.changeQuery()
    },
    // 处理选中或取消广告位后，树形结构数据的变化，统一处理，只需完成已勾选广告位
    handleCheck () {
      // 选中的广告位id列表
      const checkedList = this.configuredList.map((item) => item.placeId)
      this.placeTree.forEach((app, index) => {
        ;(app.childList || []).forEach((place) => {
          // 广告位选中状态
          this.$set(place, 'checked', checkedList.includes(place.placeId))
        })
        // 应用选中状态
        this.$set(
          app,
          'indeterminate',
          app.childList.filter((item) => item.checked).length > 0 &&
            app.childList.filter((item) => item.checked).length < app.childList.length
        )
        // 应用全选状态
        this.$set(app, 'checkAll', app.childList.filter((item) => item.checked).length === app.childList.length)
        // 应用是否展开
        this.$set(app, 'isExpand', !!app.isExpand)
      })
    },
    /**
     * 应用多选框点击事件
     * @param {*} e 应用点击事件
     * @param {*} app 应用信息
     */
    changeAppChecked (e, app) {
      // 选中状态
      const checked = e.target.checked
      app.childList.forEach((place) => {
        if (checked && !place.checked) {
          this.configuredList = [...this.configuredList, place]
        } else if (!checked && place.checked) {
          this.configuredList = this.configuredList.filter((item) => item.placeId !== place.placeId)
        }
        place.checked = checked
      })
      this.handleCheck()
      this.changeQuery()
    },
    // 点击展开图标
    changeIsExpand (e, app) {
      if (e.target.classList[0] === 'app-box' || e.target.tagName === 'svg' || e.target.tagName === 'path') {
        app.isExpand = !app.isExpand
      }
    },
    changeQuery () {
      this.formquery.placeIdList = this.configuredList.map((item) => item.placeId)
      this.$emit('changePlaceListQuery', this.formquery, this.configuredList)
    }
  }
}
</script>

<style lang="less" scoped>
.place-tree-form-wrapper-manage {
  margin-top: 24px;
  .color {
    color: @assisColor;
    cursor: pointer;
  }
  .config-place-manage {
    display: flex;
    border: 1px solid #E3E4E6;
    height: 280px;
    width: 100%;
    border-radius: 5px;
    .config-place-main-left,
    .config-place-main-right {
      flex-grow: 1;
      flex-shrink: 1;
      width: 50%;
    }
    .config-place-main-left {
      display: flex;
      flex-direction: column;
      .search-wrapper {
        min-height: 55px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 16px;
        border-bottom: 1px solid #e8e8e8;
      }
      .app-list-wrapper {
        flex-grow: 1;
        height: 0;
        overflow-y: auto;
        padding: 11px 16px 16px;
        border-right: 1px solid #e8e8e8;
        .app-item {
          .app-box {
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;
            .appBoxWrapper {
              display: flex;
              .appName {
                max-width: 100%;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                margin-left: 10px;
                color: #5A607F;
              }
            }
            .arrow {
              width: calc(16em / 14);
              height: calc(16em / 14);
              transition: all 0.5s;
            }
            .arrow_up {
              transform: rotate(180deg);
            }
            .icon {
              width: 14px;
              height: 14px;
              margin-left: 5px;
              &.app-arrow {
                cursor: pointer;
              }
            }
          }
          .place-list {
            display: flex;
            flex-direction: column;
            .place-checkbox {
              margin-left: 20px;
              padding: 0;
              color: #5A607F;
            }
          }
        }
      }
    }
    .config-place-main-right {
      display: flex;
      flex-direction: column;
      .config-place-main-right-header {
        min-height: 55px;
        border-bottom: 1px solid #e8e8e8;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 16px;
        color: #344563;
      }
      .check-list {
        flex-grow: 1;
        height: 0;
        overflow-y: auto;
        padding: 11px 16px 16px;
        .check-item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          color: #5A607F;
          .close-icon,
          .setting-icon {
            margin-left: 10px;
            color: @compDisabledColor;
            font-size: 12px;
            cursor: pointer;
          }
        }
      }
      ::v-deep {

        .ant-empty-description {
          span {
            font-size: 14px;
          }
        }
      }
    }
  }
}
</style>
