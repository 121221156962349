<template>
  <div class="sdk-version-manage-container">
    <sdkVersionSearch @handleAutoSearch="handleAutoSearch" />
    <sdkVersionTable ref="table" />
  </div>
</template>

<script>
import sdkVersionSearch from './sdkVersionSearch'
import sdkVersionTable from './sdkVersionTable'
export default {
  components: { sdkVersionSearch, sdkVersionTable },
  methods: {
    handleAutoSearch (query) {
      this.$nextTick(() => {
        this.$refs.table.handleQuery(query)
      })
    }
  }
}
</script>

<style lang="less" scoped></style>
