<template>
  <div class="group-rile-form-wrapper">
    <a-form-model-item class="form-title">
      <m-tips
        slot="label"
        :content="`设置屏蔽规则后，命中该规则的用户将不会展示广告。`"
        :title="'屏蔽规则'"
        :width="200"
      ></m-tips>
      <!-- 添加规则 -->
      <a-popover
        ref="popover"
        placement="rightTop"
        trigger="click"
        arrowPointAtCenter
        :overlayStyle="{ width: '420px' }"
      >
        <span class="addbtn"><a-icon type="plus" class="plus" /> 新增规则</span>
        <template #content>
          <div class="rules_wrapper">
            <ul v-for="item in groupRules" :key="item.type">
              <template v-for="(child, c) in item.items">
                <li
                  :class="{ hasChecked: formquery[child.showKey], noChecked: !formquery[child.showKey] }"
                  :key="c"
                  @click="handleClickRuleItemShow(child.showKey)"
                >
                  {{ child.label }}
                </li>
              </template>
            </ul>
          </div>
        </template>
      </a-popover>
    </a-form-model-item>
    <!-- 时间策略 -->
    <template v-if="formquery.timeRuleSts">
      <a-form-model-item label="时间规则">
        <m-week-times v-model="formquery.timeRules"></m-week-times>
      </a-form-model-item>
      <a-form-model-item :wrapperCol="{ offset: 2, span: 12 }">
        <div class="line"></div>
      </a-form-model-item>
    </template>
    <!-- 地区策略 -->
    <template v-if="formquery.areaRuleSts">
      <a-form-model-item label="地区">
        <a-icon @click="handleCloseRule('areaRuleSts')" type="close-circle" class="close-circle"></a-icon>
      </a-form-model-item>
      <a-form-model-item :wrapperCol="{ offset: 2, span: 12 }">
        <div class="form-list-one">
          <a-select class="type" v-model="formquery.areaRuleType">
            <a-select-option v-for="child in optionsStr2" :key="child.value" :value="child.value">{{
              child.label
            }}</a-select-option>
          </a-select>
          <el-cascader
            style="width: 67.9%"
            v-model="formquery.areaRules"
            placeholder="请选择地区"
            collapse-tags
            :options="areaList"
            :props="{
              multiple: true
            }"
          />
        </div>
      </a-form-model-item>
    </template>
    <!-- sdk版本策略 -->
    <template v-if="formquery.sdkRuleSts">
      <a-form-model-item label="SDK版本">
        <a-icon type="close-circle" @click="handleCloseRule('sdkRuleSts')" class="close-circle"></a-icon>
      </a-form-model-item>
      <a-form-model-item :wrapperCol="{ offset: 2, span: 12 }">
        <div class="form-list-one">
          <a-select class="type" v-model="formquery.sdkRuleType">
            <a-select-option v-for="child in options4" :key="child.value" :value="child.value">{{
              child.label
            }}</a-select-option>
          </a-select>
          <a-input
            style="width: 67.9%"
            allowClear
            placeholder="示例：5.0.0，可填写多个版本号，用中文或英文逗号隔开"
            v-model.trim="formquery.sdkRules"
            autocomplete="off"
            @input="handleInputSDK"
          />
        </div>
      </a-form-model-item>
    </template>

    <!-- 渠道策略 -->
    <template v-if="formquery.channelRuleSts">
      <a-form-model-item label="渠道">
        <a-icon @click="handleCloseRule('channelRuleSts')" type="close-circle" class="close-circle"></a-icon>
      </a-form-model-item>
      <a-form-model-item :wrapperCol="{ offset: 2, span: 12 }">
        <div class="form-list-one">
          <a-select class="type" v-model="formquery.channelRuleType">
            <a-select-option v-for="child in optionsStr2" :key="child.value" :value="child.value">{{
              child.label
            }}</a-select-option>
          </a-select>
          <a-input
            allowClear
            style="width: 67.9%"
            placeholder="可填写多个渠道，用中文或英文逗号隔开"
            v-model.trim="formquery.channelRules"
            autocomplete="off"
          />
        </div>
      </a-form-model-item>
    </template>

    <!-- 设备类型策略 -->
    <template v-if="formquery.equipmentRuleSts">
      <a-form-model-item label="设备类型">
        <a-icon @click="handleCloseRule('equipmentRuleSts')" type="close-circle" class="close-circle"></a-icon>
      </a-form-model-item>
      <a-form-model-item :wrapperCol="{ offset: 2, span: 12 }">
        <div class="form-list-one">
          <a-select class="type" v-model="formquery.equipmentRuleType">
            <a-select-option v-for="child in optionsStr2" :key="child.value" :value="child.value">{{
              child.label
            }}</a-select-option>
          </a-select>
          <a-input
            allowClear
            style="width: 67.9%"
            placeholder="可填写多个设备ID，用中文或英文逗号隔开"
            v-model.trim="formquery.equipmentRules"
            autocomplete="off"
          />
        </div>
      </a-form-model-item>
    </template>

    <!-- 日期策略 -->
    <template v-if="formquery.dateRuleSts">
      <a-form-model-item label="日期规则">
        <div class="dateForm">
          <span class="addbtn" @click="handleAddDateRuleItem">
            <a-icon type="plus" /> 添加日期规则</span>
          <a-icon
            type="close-circle"
            @click="handleCloseRule('dateRuleSts')"
            class="closeDate"
          ></a-icon>
        </div>
      </a-form-model-item>
      <template v-for="(item, index2) in formquery.dateRules">
        <a-form-model-item :key="index2 + '2'" :label="'日期规则' + (index2 + 1)">
          <a-icon
            type="close-circle"
            @click="handleDeleteRuleItem(index2, 'dateRules')"
            class="close-circle"
          ></a-icon>
        </a-form-model-item>
        <a-form-model-item :key="index2 + '3'" :wrapperCol="{ offset: 2,span: 12 }">
          <div class="form-item-more">
            <div class="form-list">
              <div class="form-list-item">
                <a-select class="type" v-model="formquery.dateRuleType" :disabled="index2 !== 0">
                  <a-select-option v-for="child in optionsStr2" :key="child.value" :value="child.value">{{
                    child.label
                  }}</a-select-option>
                </a-select>
                <a-range-picker
                  style="display: inline-block; width: 69%"
                  format="YYYY-MM-DD"
                  v-model="formquery.dateRules[index2]"
                  :allowClear="false"
                />
              </div>
            </div>
          </div>
        </a-form-model-item>
      </template>
      <a-form-model-item :wrapperCol="{ offset: 2, span: 12 }">
        <div class="line"></div>
      </a-form-model-item>
    </template>
    <!-- 设备品牌策略 -->
    <template v-if="formquery.brandRuleSts">
      <a-form-model-item label="设备品牌">
        <a-icon @click="handleCloseRule('brandRuleSts')" type="close-circle" class="close-circle"></a-icon>
      </a-form-model-item>
      <a-form-model-item :wrapperCol="{ offset: 2, span: 12 }">
        <div class="form-list-one">
          <a-select class="type" v-model="formquery.brandRuleType">
            <a-select-option v-for="child in optionsStr2" :key="child.value" :value="child.value">{{
              child.label
            }}</a-select-option>
          </a-select>
          <m-select-more
            style="width: 67.9%"
            class="search_item"
            v-if="brandList.length"
            v-model="formquery.brandRules"
            :allData="brandList"
            :searchById="false"
            :hasIcon="false"
            :showId="false"
            label="设备品牌"
            :showLabel="false"
          />
        </div>
      </a-form-model-item>
    </template>
    <!-- 网络类型策略 -->
    <template v-if="formquery.nettypeRuleSts">
      <a-form-model-item label="网络类型">
        <a-icon @click="handleCloseRule('nettypeRuleSts')" type="close-circle" class="close-circle"></a-icon>
      </a-form-model-item>
      <a-form-model-item :wrapperCol="{ offset: 2, span: 12 }">
        <div class="form-list-one">
          <a-select class="type" v-model="formquery.nettypeRuleType">
            <a-select-option v-for="child in optionsStr2" :key="child.value" :value="child.value">{{
              child.label
            }}</a-select-option>
          </a-select>
          <a-select
            style="width: 67.9%"
            placeholder="请选择网络类型"
            :maxTagCount="3"
            mode="multiple"
            v-model="formquery.nettypeRules"
          >
            <a-select-option v-for="item in netTypeList" :key="item" :value="item">{{ item }}</a-select-option>
          </a-select>
        </div>
      </a-form-model-item>
    </template>
    <!-- 系统版本策略 -->
    <template v-if="formquery.sysversionRuleSts">
      <a-form-model-item label="系统版本">
        <a-icon @click="handleCloseRule('sysversionRuleSts')" type="close-circle" class="close-circle"></a-icon>
      </a-form-model-item>
      <a-form-model-item :wrapperCol="{ offset: 2, span: 12 }">
        <div class="form-list-one">
          <a-select class="type" v-model="formquery.sysversionRuleType">
            <a-select-option v-for="child in options4" :key="child.value" :value="child.value">{{
              child.label
            }}</a-select-option>
          </a-select>
          <a-input
            style="width: 67.9%"
            allowClear
            placeholder="可填写多个系统版本，用中文或英文逗号隔开"
            v-model.trim="formquery.sysversionRules"
            autocomplete="off"
          />
        </div>
      </a-form-model-item>
    </template>
    <!-- 应用版本策略 -->
    <template v-if="formquery.appversionRuleSts">
      <a-form-model-item label="应用版本">
        <a-icon
          type="close-circle"
          @click="handleCloseRule('appversionRuleSts')"
          class="close-circle"
        ></a-icon>
      </a-form-model-item>
      <a-form-model-item :wrapperCol="{ offset: 2,span: 12 }">
        <div class="form-list-one">
          <a-select class="type" v-model="formquery.appversionRuleType">
            <a-select-option v-for="child in options4" :key="child.value" :value="child.value">{{
              child.label
            }}</a-select-option>
          </a-select>
          <a-input
            style="width: 67.9%"
            allowClear
            placeholder="可填写多个应用版本，用中文或英文逗号隔开"
            v-model.trim="formquery.appversionRules"
            autocomplete="off"
          />
        </div>
      </a-form-model-item>
    </template>

    <!-- 子渠道策略 -->
    <template v-if="formquery.childchannelRuleSts">
      <a-form-model-item label="子渠道">
        <a-icon @click="handleCloseRule('childchannelRuleSts')" type="close-circle" class="close-circle"></a-icon>
      </a-form-model-item>
      <a-form-model-item :wrapperCol="{ offset: 2, span: 12 }">
        <div class="form-list-one">
          <a-select class="type" v-model="formquery.childchannelRuleType">
            <a-select-option v-for="child in optionsStr2" :key="child.value" :value="child.value">{{
              child.label
            }}</a-select-option>
          </a-select>
          <a-input
            allowClear
            style="width: 67.9%"
            placeholder="可填写多个子渠道，用中文或英文逗号隔开"
            v-model.trim="formquery.childchannelRules"
            autocomplete="off"
          />
        </div>
      </a-form-model-item>
    </template>

    <!-- 自定义规则策略 -->
    <template v-if="formquery.custRuleSts">
      <a-form-model-item label="自定义">
        <span class="addbtn" @click="handleAddCustRuleItem"><a-icon type="plus" /> 添加自定义规则</span>
      </a-form-model-item>
      <template v-for="(item, index) in formquery.custRules">
        <a-form-model-item :key="index + '0'" :label="'自定义规则' + (index + 1)">
          <a-icon class="timeClose" type="close" @click="handleDeleteTimeRule(index)" icon="close"></a-icon>
        </a-form-model-item>
        <a-form-model-item :wrapperCol="{ offset: 2, span: 12 }" :key="index + '4'">
          <div class="form-item-more">
            <div class="form-list">
              <div class="form-list-item cust">
                <div class="cust-rule-item">
                  <div class="cust-rule-item-top">
                    <div class="cust-rule-item-top-top">
                      <a-form-model-item
                        class="type"
                        :prop="`custRules[${index}].ruleKey`"
                        :rules="[{ required: true, message: `请输入key值`, trigger: 'blur' }]"
                      >
                        <a-input
                          allowClear
                          placeholder="请输入key值"
                          v-model.trim="item.ruleKey"
                          autocomplete="off"
                        />
                      </a-form-model-item>
                      <a-select style="width: 69%;" v-model="item.ruleType">
                        <a-select-option v-for="child in optionsStr2" :key="child.value" :value="child.value">{{
                          child.label
                        }}</a-select-option>
                      </a-select>
                    </div>
                    <span
                      v-if="upfile === 1"
                      class="addbtn"
                      style="margin-bottom: 14px;"
                      :default-checked="item.isFile === 1"
                      @click="handleChangeIsFile(item)"
                    >
                      切换成{{ item.isFile === 1 ? '手动输入' : '文件上传' }}
                    </span>
                    <!-- 文件上传 -->
                    <div class="file-box" v-if="+item.isFile === 1 && item.fileName">
                      <a-icon class="icon file" type="file-excel" />
                      <div class="close-mask">
                        <a-icon class="icon" @click="handleDeleteCustRuleItem(index)" type="close-circle" />
                        <a
                          :href="resourceUrl + item.fileName"
                          :download="item.fileName"
                          target="view_window"
                        ><a-icon
                          class="icon"
                          type="download"
                        /></a>
                      </div>
                    </div>
                    <a-upload
                      v-if="+item.isFile === 1 && !item.fileName"
                      :action="uploadUrl"
                      accept=".xlsx"
                      name="file"
                      list-type="text"
                      :show-upload-list="false"
                      :before-upload="beforeUpload"
                      @change="handleUploadChange($event, index)"
                      :headers="header"
                      :multiple="false"
                    >
                      <div class="upload-box">
                        <Upload />
                      </div>
                    </a-upload>
                    <a-icon
                      type="close-circle"
                      v-if="index !== 0"
                      @click="handleDeleteRuleItem(index, 'custRules')"
                      class="close-circle"
                    ></a-icon>
                    <!-- 手动输入 -->
                    <div class="select-box" v-if="+item.isFile === 0" :class="{materialInput: item.values.length > 0}">
                      <m-string-list placeholder="多个value值以换行或中英文逗号分隔" v-model="item.values"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-close">
              <a-button
                type="link"
                @click="handleCloseRule('custRuleSts')"
                class="close-circle"
              ></a-button>
            </div>
          </div>
        </a-form-model-item>
      </template>
    </template>
    <!-- 应用安装时间策略 -->
    <template v-if="formquery.installtimeRuleSts">
      <a-form-model-item label="应用安装时间">
        <a-icon
          type="close-circle"
          @click="handleCloseRule('installtimeRuleSts')"
          class="close-circle"
        ></a-icon>
      </a-form-model-item>
      <a-form-model-item :wrapperCol="{ offset: 2, span: 12 }">
        <div class="form-list-one">
          <a-select class="type" v-model="formquery.installtimeRuleType">
            <a-select-option v-for="child in options2Symb" :key="child.value" :value="child.value">{{
              child.label
            }}</a-select-option>
          </a-select>
          <a-input
            allowClear
            style="width: 32%; margin-right: 20px"
            placeholder="请输入应用安装时间"
            v-model.trim="formquery.installtimeRules"
            autocomplete="off"
          />
          <a-select v-model="formquery.installtimeRuleTimeType" style="width: 32.5%;">
            <a-select-option value="d">天</a-select-option>
            <a-select-option value="h">小时</a-select-option>
          </a-select>
        </div>
      </a-form-model-item>
    </template>

    <!-- 设备型号策略 -->
    <template v-if="formquery.unittypeRuleSts">
      <a-form-model-item label="设备型号">
        <a-icon @click="handleCloseRule('unittypeRuleSts')" type="close-circle" class="close-circle"></a-icon>
      </a-form-model-item>
      <a-form-model-item :wrapperCol="{ offset: 2, span: 12 }">
        <div class="form-list-one">
          <a-select class="type" v-model="formquery.unittypeRuleType">
            <a-select-option v-for="child in optionsStr2" :key="child.value" :value="child.value">{{
              child.label
            }}</a-select-option>
          </a-select>
          <a-input
            allowClear
            style="width: 67.9%"
            placeholder="可填写多个设备型号，用中文或英文逗号隔开"
            v-model.trim="formquery.unittypeRules"
            autocomplete="off"
          />
        </div>
      </a-form-model-item>
    </template>

    <!-- 运营商策略 -->
    <template v-if="formquery.operatorRuleSts">
      <a-form-model-item label="运营商">
        <a-icon @click="handleCloseRule('operatorRuleSts')" type="close-circle" class="close-circle"></a-icon>
      </a-form-model-item>
      <a-form-model-item :wrapperCol="{ offset: 2, span: 12 }">
        <div class="form-list-one">
          <a-select class="type" v-model="formquery.operatorRuleType">
            <a-select-option v-for="child in optionsStr2" :key="child.value" :value="child.value">{{
              child.label
            }}</a-select-option>
          </a-select>
          <m-select-more
            style="width: 67.9%"
            class="search_item"
            v-model="formquery.operatorRules"
            :allData="operatorList"
            :searchById="false"
            :hasIcon="false"
            :showId="false"
            label="运营商"
            :showLabel="false"
          />
        </div>
      </a-form-model-item>
    </template>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { groupRules } from './groupRules'
import GroupRuleMixins from './groupRuleMixins'
import Upload from '@/assets/icons/upload.svg?inline'
import CopySvg from '@/assets/icons/copy.svg?inline'

export default {
  name: 'BaseForm',
  components: { Upload, CopySvg },
  mixins: [GroupRuleMixins],
  data () {
    return {
      uploadUrl: process.env.VUE_APP_API_BASE_URL + '/common/upload',
      groupRules,
      // 表单默认值
      formqueryTemp: {
        // 时间
        timeRuleSts: false,
        timeRules: [
        // 周一
        [
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
        ],
        // 周二
        [
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
        ],
        // 周三
        [
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
        ],
        // 周四
        [
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
        ],
        // 周五
        [
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
        ],
        // 周六
        [
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
        ],
        // 周日
        [
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
        ]
      ],
        timeRuleType: 0,
        // 地点
        areaRuleSts: false,
        areaRules: [],
        areaRuleType: 0,
        // sdk
        sdkRules: undefined,
        sdkRuleType: 0,
        sdkRuleSts: false,
        // 渠道
        channelRules: undefined,
        channelRuleType: 0,
        channelRuleSts: false,
        // 设备
        equipmentRules: undefined,
        equipmentRuleType: 0,
        equipmentRuleSts: false,
        // 日期
        dateRules: [[]],
        dateRuleType: 0,
        dateRuleSts: false,
        // 品牌
        brandRules: [],
        brandRuleType: 0,
        brandRuleSts: false,
        // 网络类型
        nettypeRules: undefined,
        nettypeRuleType: 0,
        nettypeRuleSts: false,
        // 系统版本
        sysversionRules: [],
        sysversionRuleType: 0,
        sysversionRuleSts: false,
        // 应用版本
        appversionRules: undefined,
        appversionRuleType: 0,
        appversionRuleSts: false,
        // 子渠道
        childchannelRules: undefined,
        childchannelRuleType: 0,
        childchannelRuleSts: false,
        // 自定义
        custRules: [
          {
            ruleKey: undefined,
            fileName: undefined,
            values: [],
            isFile: 0,
            ruleType: 0
          }
        ],
        custRuleSts: false,
        // 应用安装时间
        installtimeRules: undefined,
        installtimeRuleType: 2,
        installtimeRuleSts: false,
        installtimeRuleTimeType: 'h',
        // 设备型号
        unittypeRules: undefined,
        unittypeRuleType: 0,
        unittypeRuleSts: false,
        // 运营商
        operatorRules: [],
        operatorRuleType: 0,
        operatorRuleSts: false
      },
      values: ''
    }
  },
  computed: {
    formquery: {
      get () {
        return this.query
      },
      set (val) {
        const formquery = {
          ...this.formqueryTemp,
          ...JSON.parse(JSON.stringify(val))
        }
        this.$emit('changeQuery', formquery)
      }
    },
    compList: {
      get () {
        return this.values
      },
      set (val) {
        this.$emit('change', val)
        this.$emit('input', val)
      }
    },
    ...mapState({
      // 角色
      role: (state) => state.user.roles[0],
      title: (state) => state.autoweb.title,
      adminUserId: (state) => state.user.adminUserId,
      token: (state) => state.user.token,
      resourceUrl: (state) => state.autoweb.resourceUrl,
      upfile: (state) => state.user.upfile,
      currentLoginId: (state) => state.user.id
    }),
    header () {
      return {
        Authorization: this.token
      }
    },
    isAdmin () {
      if (this.role === 'SUPER_ADMIN' || +this.adminUserId === 1) {
        return true
      }
      return false
    }
  },
  props: {
    query: {
      default: () => ({
        timeRuleSts: false,
        timeRules: [],
        timeRuleType: 0,
        areaRuleSts: false,
        areaRules: [],
        areaRuleType: 0
      }),
      type: Object
    }
  },
  created () {
    this.getAreaList()
    this.getBrandList()
  },
  methods: {
    // 修改自定义规则的是否上传文件
    handleChangeIsFile (item) {
      item.isFile = +item.isFile === 1 ? 0 : 1
    },
    handleDeleteCustRuleItem (index) {
      this.$set(this.formquery.custRules[index], 'fileName', undefined)
    },
    // 上传状态改变回调
    handleUploadChange (info, index) {
      if (info.file.status === 'uploading') {
        return
      }
      if (info.file.status === 'done') {
        const { file = {} } = info
        const { response = {} } = file
        const { data = '', code = 0 } = response
        if (code !== 200) {
          return this.$message.error('上传失败!')
        }
        this.$set(this.formquery.custRules[index], 'fileName', data)
        this.$message.success('上传成功!')
      }
    },
    // 上传前验证回调
    beforeUpload (file) {
      return file
    },
    handleInputSDK (e) {
      // sdk版本只能输入    数字 . , ，
      this.formquery.sdkRules = e.target.value.replace(/[^,^，^.^\d]/g, '')
    },
    handleCloseRule (sts) {
      this.formquery[sts] = false
    },
    handleDeleteRuleItem (index, prop) {
      this.formquery[prop].splice(index, 1)
    },
    handleDeleteTimeRule (index) {
      this.formquery.custRules.splice(index, 1)
    },
    handleAddCustRuleItem () {
      this.formquery.custRules.push({
        ruleKey: undefined,
        fileName: undefined,
        values: [],
        isFile: 0,
        ruleType: 0
      })
    },
    handleAddDateRuleItem () {
      this.formquery.dateRules.push([null, null])
    },
    handleAddTimeRuleItem () {
      this.formquery.timeRules.push([null, null])
    },
    handleClickRuleItemShow (showkey) {
      this.formquery[showkey] = true
      // 关闭下拉框
      this.$refs.popover.$children[0].sVisible = false
    },
    changeQuery () {
      this.$emit('changeQuery', this.formquery)
    }
  }
}
</script>

<style lang="less" scoped>
.group-rile-form-wrapper {
  .form-title {
    cursor: pointer;
  }
  .addbtn {
    color: @primary-color;
    cursor: pointer;
  }
  .line {
    border-bottom: 0.8px solid rgba(21,34,50,0.2);
  }
  .form-item-more {
    position: relative;
    .form-list {
      width: 100%;
      display: flex;
      position: relative;
      flex-direction: column;
      .form-list-item {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        &.cust {
          margin-bottom: 4px;
          .cust-rule-item {
            width: 100%;
            .cust-rule-item-top {
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              .cust-rule-item-top-top {
                display: flex;
              }
              ::v-deep .ant-form-item-control {
                padding-left: 0;
              }
            }
          }
        }
        .type {
          width: 29%;
          margin-right: 20px;
        }
        .select-box {
          width: 100%;
          .materialClear {
            color: @assisColor;
            cursor: pointer;
          }
          .materialAdd {
            color: @assisColor;
            cursor: pointer;
            margin-left: 10px;
          }
          .materialList {
            border-radius: 0 0 4px 4px;
            border: 1px solid @compBorderColor;
            border-top: none;
            margin-top: -2px;
            .descriptions {
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding: 0 18px;
              font-weight: 400;
              font-size: 14px;
              color: @compDisabledColor;
              .descriptions-icon {
                display: flex;
                align-items: center;
              }
              .span {
                max-width: 80%;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              }
              .icon {
                cursor: pointer;
                font-size: 12px;
                width: 14px;
                height: 14px;
              }
              .icon:last-child {
                margin-left: 8px;
              }
            }
          }
        }
        .file-box,
        .upload-box {
          width: 60px;
          height: 60px;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px solid;
          border-radius: 5px;
          cursor: pointer;
          .icon {
            font-size: 30px;
            &.upload {
              font-size: 40px;
            }
          }
        }
        .file-box {
          color: @primary-color;
          position: relative;
          &:hover .close-mask {
            display: flex;
          }
          .close-mask {
            position: absolute;
            width: 100%;
            height: 100%;
            display: none;
            justify-content: center;
            align-items: center;
            background-color: rgba(0, 0, 0, 0.5);
            .icon {
              margin: 0 3px;
              font-size: 20px;
              color: #fff;
            }
          }
        }
        .upload-box {
          color: #D7DBEC;
        }
      }
    }
    .form-close {
      position: absolute;
      top: -10px;
      right: -6px;
      font-size: 20px;
      cursor: pointer;
    }
  }
  .dateForm {
    position: relative;
  }
  .closeDate {
    font-size: 20px;
    color: red;
    float: right
  }
  .form-list-one {
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(21,34,50,0.2);
    .type {
      width: 29%;
      margin-right: 20px;
      padding-top: 2px;
    }
  }
  ::v-deep {
    .ant-input-affix-wrapper .ant-input:not(:last-child) {
      -webkit-text-security: none;
      padding-right: 85px;
    }

    .materialInput {
      .ant-input-affix-wrapper .ant-input:not(:last-child) {
        border-radius: 4px 4px 0 0 !important;
      }
    }
  }
}
.rules_wrapper {
  position: relative;
  min-height: 180px;
  display: flex;
  ul {
    flex: 1;
    padding: 0;
    border-left: 1px solid #eee;
    &:first-child {
      border: none;
    }
    li {
      width: 100%;
      height: 30px;
      line-height: 30px;
      margin-bottom: 5px;
      text-align: center;
      border-left: 4px solid #fff;
      cursor: pointer;
      color: #666;
      &.noChecked:hover {
        background: fade(@primary-color, 5%);
        border-left: 4px solid @primary-color;
      }
      &.hasChecked {
        background: #fff;
        cursor: inherit;
        color: #ccc;
      }
    }
  }
}
.close-circle {
  color: red;
  font-size: 18px;
}
</style>
