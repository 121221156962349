<template>
  <div class="ad-plan-view-container">
    <div class="title">创建账号</div>
    <div class="prop_item_box">
      <div class="prop_item">
        <span class="label">账号类别</span>
        <span class="value">{{ adPlanInfo.roleName || '--' }}</span>
      </div>
      <div class="prop_item">
        <span class="label">账号名称</span>
        <span class="value">{{ adPlanInfo.name || '--' }}</span>
      </div>
      <div class="prop_item">
        <span class="label">登录邮箱</span>
        <span class="value">{{ adPlanInfo.loginId || '--' }}</span>
      </div>
      <div class="prop_item">
        <span class="label">联系人</span>
        <span class="value">{{ adPlanInfo.contact || '--' }}</span>
      </div>
      <div class="prop_item">
        <span class="label">联系方式</span>
        <span class="value">{{ adPlanInfo.mobile || '--' }}</span>
      </div>
      <div class="prop_item">
        <span class="label">公司名称</span>
        <span class="value">{{ adPlanInfo.company || '--' }}</span>
      </div>
      <div class="prop_item">
        <span class="label">主营应用</span>
        <span class="value">{{ adPlanInfo.mainApp || '--' }}</span>
      </div>
      <div class="prop_item flex">
        <span class="label">关联应用</span>
        <span class="prop_item_app" v-if="appResponseVoList && appResponseVoList.length > 0">
          <span v-for="(item, index) in appResponseVoList" :key="item + index" class="value">
            {{ item || '--' }} {{ index === appResponseVoList.length - 1 ? ' ' : ' 、' }}
          </span>
        </span>
        <span v-else>--</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      appResponseVoList: [],
      adPlanInfos: []
    }
  },
  computed: {},
  props: {
    adPlanInfo: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {},
  watch: {
    adPlanInfo: {
      handler (val) {
        this.adPlanInfos = JSON.parse(JSON.stringify(val))
        this.appResponseVoList = this.adPlanInfos.appResponseVoList?.map((element) => element.name)
      },
      deep: true,
      immediate: true
    }
  },
  mounted () {}
}
</script>

<style lang="less" scoped>
.ad-plan-view-container {
  padding-left: 5px;
  margin-bottom: 20px;
  .title {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 20px;
  }
  .prop_item_box {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .prop_item {
      height: 40px;
      line-height: 40px;
      width: 30%;
      font-weight: 500;
      .label {
        display: inline-block;
        width: 90px;
      }
      .value {
        font-size: 13px;
      }
      .prop_item_app {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: block;
        width: 200px;
      }
    }
    .flex {
      display: flex;
      .label {
        width: 90px;
        display: block;
      }
    }
  }
}
</style>
