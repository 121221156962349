<template>
  <div class="personnel-container">
    <div class="ponsonnel_search">
      <a-button type="primary" @click="handleClickAdd()">新增人员</a-button>
      <div style="display: flex; align-items: center">
        <a-select style="width: 220px; margin-right: 10px" v-model="query.role" allowClear placeholder="请选择角色">
          <a-select-option v-for="item in roleList" :key="item.id">
            {{ item.name }}
          </a-select-option>
        </a-select>
        <a-input style="width: 220px" allowClear placeholder="请输入用户名称或用户邮箱" v-model.trim="query.name"></a-input>
        <a-button type="primary" class="btn" @click="handleAutoSearch">查询</a-button>
      </div>
    </div>
    <div class="table">
      <a-table
        class="m_table_cust"
        :loading="loading"
        :pagination="false"
        :columns="columns"
        :data-source="dataList"
        :rowKey="(record, index) => index"
        bordered
      >
        <div slot="appResponseVoList" slot-scope="appResponseVoList, record">
          <span style="color:#747474" v-if="+record.appType === 1">已关联全部应用</span>
          <span v-else><a-button size="small" type="link" style="margin: 0; padding-left: 0">已关联应用</a-button>{{ appResponseVoList.length }}个</span>
        </div>
        <div slot="sts" slot-scope="sts">
          <span :style="{ color: sts === 'A' ? '#15ad31' : '#F83366' }">{{ sts === 'A' ? '开启' : '关闭' }}</span>
        </div>
        <div class="action-slot" slot="action" slot-scope="text, record" v-if="record.roleName !== '代运营'" >
          <span
            class="btn"
            @click="handleClickUpdatePlace(record)"
          >修改</span
          >
          <span class="line">|</span>
          <a-popconfirm title="确认删除该用户?" ok-text="是" cancel-text="否" @confirm="handleDelete(record)">
            <span class="btn">删除</span>
          </a-popconfirm>
        </div>
      </a-table>
      <m-empty v-if="dataList.length === 0" style="height: 70vh; width: 100%">
        <template #description> 暂无数据 </template>
      </m-empty>
      <a-pagination
        class="pagination"
        show-size-changer
        :current="query.page"
        :show-total="(total) => `共 ${total} 条`"
        :total="total"
        @change="handleChangePage"
        @showSizeChange="onShowSizeChange"
      />
    </div>
    <PersonModal
      v-if="addPersonnel"
      :personInfo="personInfo"
      :isAdd="isAdd"
      :visible="addPersonnel"
      :roleList="roleList"
      @modalCancel="addPersonnel = false"
      @changePerson="changePerson"
    />
  </div>
</template>

<script>
import { subAccountPager, deleteUser, getRoleList } from '@/api/system'
// import NewStaff from '../components/newStaff'
import PersonModal from '../components/personModal'
import { mapState } from 'vuex'
const columns = [
  {
    dataIndex: 'name',
    title: '人员名称',
    key: 'name',
    align: 'left',
    width: 200
  },
  {
    dataIndex: 'loginId',
    title: '登录邮箱',
    key: 'loginId',
    align: 'left',
    width: 260
  },
  {
    dataIndex: 'roleName',
    title: '角色',
    key: 'roleName',
    align: 'left'
  },
  {
    dataIndex: 'appResponseVoList',
    title: '已关联应用',
    key: 'appResponseVoList',
    align: 'left',
    scopedSlots: { customRender: 'appResponseVoList' }
  },
  {
    dataIndex: 'sts',
    title: '状态',
    key: 'sts',
    align: 'left',
    scopedSlots: { customRender: 'sts' }
  },
  {
    dataIndex: 'action',
    title: '操作',
    scopedSlots: { customRender: 'action' },
    align: 'left'
  }
]
export default {
  name: 'Personnel',
  components: { PersonModal },
  data () {
    return {
      isAdd: '',
      columns,
      addPersonnel: false,
      personInfo: {},
      loading: false,
      pid: [],
      query: {
        page: 1,
        limit: 10,
        role: undefined,
        name: undefined
      },
      total: 0,
      roleList: [],
      dataList: []
    }
  },
  computed: {
    ...mapState({
      role: (state) => state.user.roles[0],
      mediaAccess: (state) => state.user.mediaAccess
    })
  },
  mounted () {
    this.getsubAccountPager()
    this.getRoleList()
  },
  methods: {
    // 获取报表数据
    async getsubAccountPager () {
      this.loading = true
      const res = await subAccountPager(this.query)
      if (res.code === 200) {
        this.loading = false
        this.dataList = res.data.items || []
        this.total = res.data.total
      }
    },
    // 新增人员
    handleClickAdd () {
      this.isAdd = true
      this.addPersonnel = true
    },
    handleAutoSearch (value) {
      this.query.page = 1
      this.getsubAccountPager()
    },
    // 修改
    handleClickUpdatePlace (record) {
      this.isAdd = false
      this.personInfo = record
      this.addPersonnel = true
    },
    changePerson () {
      this.query.page = 1
      this.getsubAccountPager()
    },
    // 删除
    handleDelete (record) {
      deleteUser({ id: record.id, sts: 'D' }).then((resp) => {
        if (resp.code === 200) {
          this.$message.success('删除成功')
          this.handleAutoSearch()
        }
      })
    },
    // 分页
    onShowSizeChange (current, size) {
      this.query.page = 1
      this.query.limit = size
      this.getsubAccountPager()
    },
    handleChangePage (page) {
      this.query.page = page
      this.getsubAccountPager()
    },
    // 获取角色列表
    getRoleList () {
      getRoleList().then((res) => {
        this.roleList = res.data || []
        if (this.role === 'ADMIN' && this.mediaAccess !== 1) {
          this.roleList = this.roleList.filter((item) => item.id !== 7)
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.personnel-container {
  margin: 0 10px;
  border-radius: 5px;
  padding: 20px;
  background: #fff;
  .ponsonnel_search {
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    .btn {
      background: fade(@primary-color, 10%);
      border: 1px solid @primary-color;
      color: @primary-color;
      margin-left: 10px;
    }
  }
  .table {
    border-radius: 5px;
    height: 100%;
    min-height: 70vh;
    .pagination {
      margin-top: 20px;
    }
    .action-slot {
      .btn {
        color: @primary-color;
        cursor: pointer;
      }
      .line {
        color: #ccc;
      }
      span {
        margin-right: 10px;
      }
    }
  }
}
</style>
