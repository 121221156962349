<template>
  <div class="alliance-container">
    <div class="allianceSerachForm">
      <a-range-picker
        class="search_item"
        :allowClear="false"
        format="YYYY-MM-DD"
        :value="rangePickerDate"
        @change="handleChangeTime"
        :disabledDate="disabledDate"
        :ranges="{
          今日: [initDate(0, 0).start, initDate(0, 0).end],
          昨日: [initDate(1, 1).start, initDate(1, 1).end],
          最近一周: [initDate(6, 0).start, initDate(6, 0).end],
          最近一个月: [initDate(30, 0).start, initDate(30, 0).end]
        }"
        :placeholder="['Start', 'End']"
      >
      </a-range-picker>
      <a-select
        class="search_item"
        allowClear
        @change="handleChange"
        :filter-option="filterOption"
        showSearch
        v-model="searchFormData.operateAccount"
        placeholder="请选择账户"
        v-if="SUPER_ADMIN"
      >
        <a-select-option v-for="pitem in adminListOptions" :key="pitem.id" :value="pitem.id">{{
          pitem.company
        }}</a-select-option>
      </a-select>
      <a-select
        class="search_item"
        allowClear
        :filter-option="filterOption"
        showSearch
        @change="handleChange"
        v-model="searchFormData.operateType"
        placeholder="请选择操作类型"
      >
        <a-select-option v-for="pitem in operateTypeOptions" :key="pitem.value" :value="pitem.value">{{
          pitem.label
        }}</a-select-option>
      </a-select>
      <a-input class="search_item" v-model.trim="searchFormData.operateObject" placeholder="操作对象" />
      <a-input class="search_item" v-model.trim="searchFormData.operateUser" placeholder="操作人" />
      <a-input class="search_item" v-model.trim="searchFormData.operateContent" placeholder="操作内容" />
      <a-button class="search_btn" type="primary" @click="handleSerach">查询</a-button>
    </div>
    <!-- 表格 -->
    <div class="table">
      <a-table
        :columns="columns"
        :data-source="data"
        class="m_table_cust"
        style="padding: 0"
        :scroll="{ x: 1200 }"
        :pagination="false"
        :loading="spinLoading"
        rowKey="id"
        bordered
      >
        <span slot="tags" slot-scope="tags, record">
          <a-tag :color="record.operateMotion == '5' ? 'green' : record.operateMotion == '6' ? 'volcano' : 'orange'">{{
            tags
          }}</a-tag>
        </span>
        <div slot="operateContent" slot-scope="text, record">
          <p style="display: flex" v-for="(item, index) in record.operateContent.split(',')" :key="index">
            <span
              style="white-space: nowrap; font-weight: 600; font-size: 16px; line-height: 25px"
            >{{ index + 1 }}.</span
            >
            <span style="text-align: left; line-height: 25px">{{ item }}</span>
          </p>
        </div>
      </a-table>
      <a-pagination
        v-if="data.length > 0"
        class="pagination"
        show-size-changer
        :current="querData.page"
        :total="total"
        :show-total="(total) => `共 ${total} 条`"
        @change="handleCurrentPage"
        @showSizeChange="handleChangePageSize"
      />
      <m-empty v-else style="height: 500px">
        <template #description> 暂无数据 </template>
      </m-empty>
    </div>
  </div>
</template>
<script>
import { getAdminList, getLogPage } from '@/api/system'
// import { mapState } from 'vuex'
import { mapGetters } from 'vuex'
import mixDate from '@/mixins/initDate'
import { getDuration } from '@/utils/duration'
export default {
  name: 'OperationLog',
  mixins: [mixDate],
  data () {
    return {
      searchFormData: {},
      spinLoading: false,
      modelForm: {},
      platListOptions: [],
      operateTypeOptions: [
        {
          label: '应用',
          value: 1
        },
        {
          label: ' 广告位',
          value: 2
        },
        {
          label: '瀑布流属性配置',
          value: 3
        },
        {
          label: '广告平台',
          value: 4
        },
        {
          label: '数据预警',
          value: 5
        },
        {
          label: '账户信息 ',
          value: 6
        },
        {
          label: '账户权限',
          value: 7
        },
        {
          label: '接入中心',
          value: 8
        },
        {
          label: '广告源',
          value: 9
        },
        {
          label: 'A/B测试',
          value: 10
        },
        {
          label: '流量分组',
          value: 11
        },
        {
          label: '账户',
          value: 12
        },
        {
          label: '直客广告',
          value: 13
        },
        {
          label: '资质审核',
          value: 14
        },
        {
          label: '竞价广告',
          value: 15
        },
        {
          label: '屏蔽管理',
          value: 16
        }
      ],
      adminListOptions: [],
      layout: {
        labelCol: { span: 5 },
        wrapperCol: { span: 16 }
      },
      data: [],
      columns: [
        {
          title: '操作时间',
          align: 'left',
          width: '200px',
          dataIndex: 'operateTime',
          key: 'operateTime'
        },
        {
          title: '所属账户',
          dataIndex: 'operateAccount',
          width: '200px',
          align: 'left',
          key: 'operateAccount'
        },
        {
          title: '操作人',
          key: 'operateUser',
          width: '200px',
          align: 'left',
          dataIndex: 'operateUser'
        },
        {
          title: '操作类型',
          width: 120,
          key: 'operateTypeName',
          align: 'left',
          dataIndex: 'operateTypeName'
        },
        {
          title: '操作对象',
          align: 'left',
          width: 120,
          key: 'operateObject',
          dataIndex: 'operateObject'
        },
        {
          title: '操作动作',
          key: 'operateMotionName',
          width: 100,
          align: 'left',
          dataIndex: 'operateMotionName',
          scopedSlots: { customRender: 'tags' }
        },
        {
          title: '操作内容',
          key: 'operateContent',
          align: 'left',
          width: 300,
          dataIndex: 'operateContent',
          scopedSlots: { customRender: 'operateContent' }
        }
      ],
      querData: {
        page: 1,
        limit: 10
      },
      adPlanOptions: [],
      fileList: [],
      currentAccessType: undefined,
      total: 0
    }
  },
  computed: {
    ...mapGetters(['userInfo'])
  },
  created () {
    // 判断是否是超级管理员
    this.SUPER_ADMIN = this.userInfo.roleName && this.userInfo.roleName === '超级管理员'
    if (this.userInfo.roleName && this.userInfo.roleName !== '超级管理员') {
      this.columns = this.columns.filter((item) => item.key !== 'operateAccount')
    }
    const { startDate, endDate, end, start } = this.initDate(7, 0)
    this.rangePickerDate = [start, end]
    this.searchFormData = {
      ...this.searchFormData,
      startTime: startDate,
      endTime: endDate
    }
    this.getLogPage({ ...this.querData, startTime: startDate, endTime: endDate })
    this.getAdminList()
  },
  methods: {
    // 账户下拉框
    async getAdminList (data) {
      const res = await getAdminList(data)
      if (res.code === 200 && res.data) {
        this.adminListOptions = res.data || []
      }
    },
    // 获取操作日志
    async getLogPage (data) {
      const res = await getLogPage(data)
      this.spinLoading = true
      if (res.code === 200 && res.data) {
        const tempData = res.data.items || []
        tempData.length !== 0 &&
          tempData.forEach((item) => {
            item.operateTypeName = this.formatoperateType(item.operateType)
            item.operateMotionName = this.formatoperateMotion(item.operateMotion)
          })
        this.data = tempData
      }
      this.spinLoading = false
      this.total = res.data && res.data.total
    },
    // 不可选时间
    disabledDate (current) {
      // 默认大于今天的时间 小于2021.9
      return (current && current < this.initDate(getDuration(), 0).start) || current > this.initDate(0, 0).end
    },
    // 搜索
    handleSerach () {
      this.querData.page = 1
      this.getLogPage({ ...this.querData, ...this.searchFormData })
    },
    handleChange (value) {
      if (!value) {
        this.handleSerach()
      }
    },
    handleChangeTime (date, dateString) {
      this.rangePickerDate = dateString
      this.searchFormData = {
        ...this.searchFormData,
        startTime: dateString[0],
        endTime: dateString[1]
      }
    },
    formatoperateType (key) {
      switch (key) {
        case '1':
          return ' 应用'
        case '2':
          return '广告位'
        case '3':
          return '瀑布流属性配置'
        case '4':
          return '广告平台'
        case '5':
          return '数据预警'
        case '6':
          return '账户信息'
        case '7':
          return '账户权限'
        case '8':
          return '接入中心'
        case '9':
          return '广告源'
        case '10':
          return 'A/B测试'
        case '11':
          return '流量分组'
        case '12':
          return '账户'
        case '13':
          return '直客广告'
        case '14':
          return '资质审核'
        case '15':
          return '竞价广告'
        case '16':
          return '屏蔽管理'
        default:
          return ''
      }
    },
    formatoperateMotion (key) {
      switch (key) {
        case '0':
          return ' 未知'
        case '1':
          return ' 新增'
        case '2':
          return '修改'
        case '3':
          return '查询'
        case '4':
          return '删除'
        case '5':
          return '登录'
        case '6':
          return '退出'
        default:
          return ''
      }
    },
    filterOption (input, option) {
      return option.componentOptions.children[0].text.indexOf(input) >= 0
    },
    // 分页
    handleCurrentPage (page) {
      this.querData.page = page
      this.getLogPage({
        ...this.querData,
        ...this.searchFormData
      })
    },
    handleChangePageSize (current, size) {
      this.querData.page = 1
      this.querData.limit = size
      this.getLogPage({
        ...this.querData,
        ...this.searchFormData
      })
    }
  }
}
</script>
<style lang="less" scoped>
@import '~@/assets/less/mixins/redefineAnt.less';
.alliance-container {
  padding: 0 10px;
  background: #fff;
  margin: 10px;
  border-radius: 10px;
  .allianceSerachForm {
    border-radius: 5px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding: 20px 20px 0;
    .search_item {
      width: 220px;
      margin-right: 10px;
      margin-bottom: 10px;
    }
  }
  .table {
    border-radius: 5px;
    background: #fff;
    padding: 20px;
    width: 100%;
    .pagination {
      padding: 20px 0;
    }
  }
}
</style>
