<!--
 * @Author: zt zhoutao@ydmob.com
 * @Date: 2024-04-18 15:26:28
 * @LastEditors: zhoutao mrzater@163.com
 * @LastEditTime: 2024-11-11 12:28:34
 * @FilePath: /mediatom-web/src/components/common/GoBack/index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="go-back">
    <a-button type="link" @click="$emit('goBack')"><Arrow class="svg-icon"/>返回</a-button>
    <div class="line"></div>
    <template v-if="title1">
      <span class="name">{{ title1 }}</span>
      <a-icon type="double-right" class="icon" />
    </template>
    <template v-if="title2">
      <span class="name">{{ title2 }}</span>
      <a-icon type="double-right" class="icon" />
    </template>
    <span class="name">{{ title3 }}</span>
  </div>
</template>

<script>
import Arrow from '@/assets/icons/arrow.svg?inline'
export default {
  name: 'GoBack',
  components: { Arrow },
  props: {
    title1: {
      default: '',
      type: String
    },
    title2: {
      default: '',
      type: String
    },
    title3: {
      default: '',
      type: String
    }
  }
}
</script>

<style lang="less" scoped>
  .go-back{
    background-color: #fff;
    height: 60px;
    position: fixed;
    top: 60px;
    left: 0;
    width: 100%;
    // min-width: 1280px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    box-shadow: 0 1px 4px 0 rgba(21, 34, 50, 0.08);
    z-index: 10;
    .svg-icon{
      vertical-align: top;
      transform: rotate(90deg);
      width: 20px;
      height: 20px;
    }
    .line{
      height: 24px;
      width: 2px;
      background-color: rgba(31, 34, 50, 0.08);
    }
    .name{
      font-size: 15px;
      color: #5a607f;
      margin: 0 20px;
    }
    .icon{
        width: 9px;
    }
  }
</style>
