<!--
 * @Author: zt zhoutao@ydmob.com
 * @Date: 2024-04-11 18:41:29
 * @LastEditors: zt zhoutao@ydmob.com
 * @LastEditTime: 2024-04-12 14:48:51
 * @FilePath: /mediatom-web/src/components/CreativeForm/DspSoourceTreeForm/index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="dsp-source-tree-form-wrapper">
    <a-form-model-item
      prop="dspIdList"
      label="绑定广告源"
    >
      <m-select-more
        style="width: 60%;"
        v-model="form.dspIdList"
        :allData="dspList"
        :searchById="true"
        :hasIcon="false"
        :showLabel="false"
        :showId="true"
        label="广告源"
        :showSelectedPart="true"
        width="450px"
        :canSelectAll="true"
      />
    </a-form-model-item>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import IosSvg from '@/assets/icons/ios.svg?inline'
import AndroidSvg from '@/assets/icons/android.svg?inline'
export default {
  name: 'DspSourceTreeForm',
  components: { IosSvg, AndroidSvg },
  data () {
    return {
    }
  },
  computed: {
    ...mapState({
      // 角色
      role: (state) => state.user.roles[0],
      title: (state) => state.autoweb.title,
      adminUserId: (state) => state.user.adminUserId,
      currentLoginId: (state) => state.user.id
    }),
    isAdmin () {
      if (this.role === 'SUPER_ADMIN' || +this.adminUserId === 1) {
        return true
      }
      return false
    },
    form: {
      get () {
        return this.formquery
      },
      set (val) {
        this.$emit('changeQuery', val)
      }
    }
  },
  props: {
    formquery: {
      default: () => ({
        dspIdList: []
      }),
      type: Object
    },
    dspList: {
      default: () => [],
      type: Array
    }
  },
  created () {
  },
  methods: {
  }
}
</script>

<style lang="less" scoped>
.dsp-source-tree-form-wrapper{
  background: #fff;
  border-radius: 10px;
}
</style>
