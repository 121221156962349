<!--
 * @Author: zt zhoutao@ydmob.com
 * @Date: 2024-02-26 16:50:35
 * @LastEditors: zhoutao mrzater@163.com
 * @LastEditTime: 2024-05-30 14:40:50
 * @FilePath: /viewsForManage/FunctionList/CreativeMask/index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="creative-mask-container">
    <div class="search-box">
      <a-button type="primary" icon="plus" @click="handleAddShield">新建屏蔽规则</a-button>
      <div class="search-right">
        <a-select allowClear v-model="query.type" placeholder="请选择屏蔽类型" style="width: 150px;margin-right: 5px">
          <a-select-option :key="1" v-if="role === 'SUPER_ADMIN' || +currentLoginId === 25">素材替换</a-select-option>
          <a-select-option :key="2">流量屏蔽</a-select-option>
        </a-select>
        <a-input v-model.trim="query.name" placeholder="请输入关键字"></a-input>
        <a-button type="primary" @click="handleSearch">查询</a-button>
      </div>
    </div>
    <div class="table-box">
      <a-spin :spinning="isLoading">
        <el-table
          ref="tableSort"
          :data="tableData"
          border
          style="width: 100%"
        >
          <el-table-column fixed="left" prop="name" label="规则名称" min-width="180">
          </el-table-column>
          <el-table-column fixed="left" label="关联应用/代码位" min-width="300">
            <template slot-scope="{ row }">
              {{ `${row.appNum}个应用，${row.placeNum}个代码位` }}
            </template>
          </el-table-column>
          <el-table-column fixed="left" label="屏蔽类型" min-width="180">
            <template slot-scope="{ row }">
              {{ getTypeName(row) }}
            </template>
          </el-table-column>
          <el-table-column fixed="left" label="修改时间" prop="updateTime" min-width="180"></el-table-column>
          <el-table-column fixed="left" label="操作" min-width="180">
            <div class="action-slot" slot-scope="{ row }">
              <span class="btn" @click="handleEdit(row)"> 编辑 </span>
              <span class="line">|</span>
              <a-popconfirm title="确认删除该替换规则?" ok-text="是" cancel-text="否" @confirm="handleDeleteShield(row)">
                <span class="btn delete"> 删除 </span>
              </a-popconfirm>
            </div>
          </el-table-column>
        </el-table>
        <a-pagination
          class="pagination"
          v-if="tableData.length > 0"
          show-size-changer
          :current="query.page"
          :total="total"
          :show-total="(total) => `共 ${total} 条`"
          @change="handleChangePage"
          @showSizeChange="onShowSizeChange"
          :pageSize="query.limit"
        />
      </a-spin>
    </div>
    <!-- <EditCreativeMaskModal
      :visible="shieldRuleVisible"
      @modalCancel="shieldRuleVisible = false"
      :shieldId="shieldId"
      :isAdd="isAdd"
      @editShieldSuccess="getTableList"
    /> -->
  </div>
</template>

<script>
// import EditCreativeMaskModal from './EditCreativeMaskModal'
import { getShieldPager, changeShieldSts } from '@/api/creativeMask'
import { mapState } from 'vuex'
export default {
  // components: { EditCreativeMaskModal },
  data () {
    return {
      query: {
        name: undefined,
        type: undefined,
        page: 1,
        limit: 10
      },
      total: 0,
      tableData: [],
      isLoading: false
      // shieldRuleVisible: false,
      // isAdd: false,
      // shieldId: undefined
    }
  },
  computed: {
    ...mapState({
      // 角色
      role: (state) => state.user.roles[0],
      title: (state) => state.autoweb.title,
      adminUserId: (state) => state.user.adminUserId,
      currentLoginId: (state) => state.user.id
    })
  },
  created () {
    this.getTableList()
  },
  methods: {
    handleSearch () {
      this.query.page = 1
      this.getTableList()
    },
    handleDeleteShield (row) {
      changeShieldSts({ id: row.id, sts: 'D' }).then((res) => {
        if (res.code === 200) {
          this.$message.success('删除成功!')
          this.getTableList()
        }
      })
    },
    handleAddShield () {
      // this.isAdd = true
      // this.shieldRuleVisible = true
      this.$router.push({
        name: 'addmaskrule'
      })
    },
    getTypeName (row) {
      return +row.type === 1 ? '素材替换' : +row.type === 2 ? '流量屏蔽' : '--'
    },
    handleEdit (row) {
      // this.isAdd = false
      // this.shieldId = row.id
      // this.shieldRuleVisible = true
      this.$router.push({
        name: 'updatemaskrule',
        query: {
          id: row.id
        }
      })
    },
    handleChangePage (page) {
      this.query.page = page
      this.getTableList()
    },
    onShowSizeChange (current, size) {
      this.query.page = 1
      this.query.limit = size
      this.getTableList()
    },
    async getTableList () {
      this.isLoading = true
      const { data = {} } = await getShieldPager(this.query)
      const { items = [], total = 0 } = data
      this.tableData = items
      this.total = total
      this.isLoading = false
    }
  }
}
</script>

<style lang="less" scoped>
.creative-mask-container {
  margin: 0 10px;
  .search-box,
  .table-box {
    border-radius: 10px;
    background-color: #fff;
    margin-bottom: 10px;
    padding: 10px;
  }
  .search-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .search-right {
      display: flex;
      input {
        width: 220px;
        margin-right: 10px;
      }
    }
  }
  .table-box {
    min-height: 200px;
    .action-slot{
      .btn{
        color: @primary-color;
        cursor: pointer;
        &.delete{
          color: red;
        }
      }
      .line{
        color: #ccc;
      }
      span{
        margin-right: 7px;
      }
    }
    .pagination {
      padding: 10px 0;
    }
  }
}
</style>
