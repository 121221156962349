<template>
  <a-modal v-model="permissionVisible" title="权限配置" @onCancel="handleCancel" @ok="handleSubmit" :width="1000">
    <div class="permission-top">
      <span class="permission-plat">广告平台权限</span>
      <a-input-search size="small" placeholder="搜索广告平台" style="width: 180px" type="text" v-model.trim="searchword">
      </a-input-search>
    </div>
    <div class="permission-checkbox-group">
      <a-checkbox @click="handleClickItem(item)" class="checkbox-item" v-for="item in searchPlatList" :key="item.id" :checked="query.platIds.includes(item.id)">{{
        item.name
      }}</a-checkbox>
    </div>
    <span style="font-size: 16px; font-weight: 600">素材审核权限</span>
    <span style="margin-left: 20px">
      <a-radio-group v-model="creative" @change="onChange">
        <a-radio :value="1"> 开启 </a-radio>
        <a-radio :value="0"> 关闭 </a-radio>
      </a-radio-group>
    </span>
    <div>
      <a-radio-group v-if="creative === 1" v-model="auditType" @change="onChangeAuditType">
        <span style="font-size: 16px; font-weight: 600">素材审核方式</span>
        <span style="margin-left: 20px">
          <a-radio :value="1"> 先审后投 </a-radio>
          <a-radio :value="0"> 先投后审 </a-radio>
        </span>
      </a-radio-group>
    </div>
    <div>
      <a-radio-group v-model="contractSts" @change="onChangeContractSts">
        <span style="font-size: 16px; font-weight: 600">ADX结算合同签署</span>
        <span style="margin-left: 20px">
          <a-radio :value="1"> 是 </a-radio>
          <a-radio :value="0"> 否 </a-radio>
        </span>
      </a-radio-group>
    </div>

  </a-modal>
</template>

<script>
import { permissionBindPlat } from '@/api/system'
import { updatePermission } from '@/api/login'
export default {
  data () {
    return {
      value: '',
      query: {
        platIds: []
      },
      searchword: '',
      contractSts: 0,
      auditType: undefined,
      creative: undefined
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    accountInfo: {
      default: () => ({
        platIds: []
      }),
      type: Object
    },
    platList: {
      type: Array,
      default: () => []
    }
  },
  watch: {
    visible: {
      handler (val) {
        if (val) {
          this.query.userId = this.accountInfo.id
          this.$set(this.query, 'platIds', this.accountInfo.platIds)
          this.searchword = ''
          // 编辑账户的creative
          this.creative = this.accountInfo.creative || 0
          this.auditType = this.accountInfo.auditType
          this.contractSts = this.accountInfo.contractSts
        }
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    permissionVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('modalCancel')
      }
    },
    searchPlatList () {
      return this.platList.filter((plat) => plat.name.includes(this.searchword))
    }
  },
  methods: {
    // 合同签署
    onChangeContractSts (e) {
      if (e.target.checked) {
        this.contractSts = e.target.value
      }
    },
    handleClickItem (item) {
      if (this.query.platIds.includes(item.id)) {
        this.query.platIds = this.query.platIds.filter((id) => +id !== +item.id)
      } else {
        this.query.platIds.push(item.id)
      }
    },
    handleCancel () {
      this.permissionVisible = false
    },
    // 开启关闭的切换
    onChange (e) {
      if (e.target.checked) {
        this.creative = e.target.value
      }
    },
    onChangeAuditType (e) {
      if (e.target.checked) {
        this.auditType = e.target.value
      }
    },
    async handleSubmit () {
      const resp = await permissionBindPlat(this.query)
      if (resp.code === 200) {
        this.$message.success('修改成功!')
        this.permissionVisible = false
        this.$emit('changeAccountInfo')
      }
      await updatePermission({
        userId: this.query.userId,
        creative: this.creative,
        auditType: this.auditType,
        contractSts: this.contractSts
      })
    }
  }
}
</script>

<style lang="less">
.permission-checkbox-group {
  height: 450px;
  overflow-y: auto;
  padding: 0;
  margin: 0;
  .checkbox-item {
    margin-left: 8px;
    overflow: hidden;
    margin-top: 10px;
    > span:not([class]) {
      width: 135px;
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      vertical-align: top;
    }
  }
}
.permission-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  .permission-plat {
    font-size: 16px;
    font-weight: 600;
  }
}
</style>
>
