<template>
  <div class="personnel-container">
    <div class="ponsonnel_search">
      <a-button type="primary" @click="handleClickAdd()">新增账户</a-button>
      <div style="display: flex; align-items: center">
        <a-range-picker
          v-model="rangePickerDate"
          style="width: 240px; margin-right: 10px"
          @change="ChangeDate"
          format="YYYY-MM-DD"
          :placeholder="['开始时间', '结束时间']"
          :disabledDate="disabledDate"
          :ranges="{
            今日: [initDate(0, 0).start, initDate(0, 0).end],
            昨日: [initDate(1, 1).start, initDate(1, 1).end],
            最近一周: [initDate(6, 0).start, initDate(6, 0).end],
            最近一个月: [initDate(30, 0).start, initDate(30, 0).end]
          }"
        >
          <!-- <a-icon slot="suffixIcon" type="calendar"/> -->
        </a-range-picker>
        <a-input
          style="width: 200px; margin-right: 10px"
          allowClear
          placeholder="请输入公司名称或账号名称或邮箱"
          v-model.trim="query.name"
        ></a-input>
        <a-input
          style="width: 200px; margin-right: 10px"
          allowClear
          placeholder="请输入应用"
          v-model.trim="query.appName"
        ></a-input>
        <a-select
          style="width: 220px; margin-right: 10px"
          v-model="query.isAdmin"
          allowClear
          placeholder="请选择账号类型"
        >
          <a-select-option v-for="item in roleList" :key="item.id">
            {{ item.name }}
          </a-select-option>
        </a-select>
        <a-button class="search_btn" type="primary" @click="handleSerach">查询</a-button>
      </div>
    </div>
    <div class="table">
      <a-table
        class="m_table_cust"
        :loading="loading"
        :pagination="false"
        :columns="columns"
        :data-source="dataList"
        :scroll="{ x: true }"
        :rowKey="(record, index) => index"
        bordered
      >
        <div slot="appResponseVoList" slot-scope="appResponseVoList, record" @click="goToApp">
          <hoverChild v-if="Number(record.isAdmin) === 1 ? record.createAppResponseVoList.length > 0 : record.appResponseVoList.length > 0" :data="record"></hoverChild>
          <div style="text-align : center;cursor: pointer" v-else>{{ '-' }}</div>
        </div>
        <div slot="sts" slot-scope="sts">
          <span :style="{ color: sts === 'A' ? '#15ad31' : '#F83366' }">{{ sts === 'A' ? '开启' : '关闭' }}</span>
        </div>
        <div class="action-slot" slot="action" slot-scope="text, record">
          <span class="btn" v-if="Number(record.isAdmin) !== 0" @click="handleClickUpdatePlace(record)">修改</span>
          <span v-if="Number(record.isAdmin) !== 0" class="line">|</span>
          <a-popconfirm title="确认删除该用户?" ok-text="是" cancel-text="否" @confirm="handleDelete(record)">
            <span class="btn" v-if="Number(record.isAdmin) !== 0" size="small">删除</span>
          </a-popconfirm>
          <template v-if="role === 'SUPER_ADMIN' && record.createUser === 1">
            <span class="line" v-if="Number(record.isAdmin) !== 0">|</span>
            <span class="btn" @click="handleClickBind(record, 1)">绑定</span>
          </template>
          <span
            class="line"
            v-if="
              (Number(record.isAdmin) !== 0 || (role === 'SUPER_ADMIN' && record.createUser === 1)) &&
                role === 'SUPER_ADMIN' &&
                record.isAdmin === '1'
            "
          >|</span
          >
          <span
            class="btn"
            v-if="role === 'SUPER_ADMIN' && record.isAdmin === '1'"
            @click="handleClickPermission(record)"
          >权限</span
          >
        </div>
      </a-table>
      <m-empty v-if="dataList.length === 0" style="height: 70vh; width: 100%">
        <template #description> 暂无数据 </template>
      </m-empty>
      <a-pagination
        class="pagination"
        show-size-changer
        :current="query.page"
        :total="total"
        :show-total="(total) => `共 ${total} 条`"
        @change="handleChangePage"
        @showSizeChange="onShowSizeChange"
      />
    </div>
    <AddAccountModal
      v-if="personVisible"
      :visible="personVisible"
      :id="formquery.id"
      :type="type"
      :recordObj="recordObj"
      :transferList="transferList"
      @modalCancel="personVisible = false"
      @changeRansferAdmin="handleSerach"
    />
    <permissionModal
      :visible="permissionVisible"
      @modalCancel="permissionVisible = false"
      :accountInfo="accountInfo"
      :platList="platList"
      @changeAccountInfo="changeAccountInfo"
    />
  </div>
</template>

<script>
import { accountPager, deleteAccount, getAdminLists } from '@/api/system'
import { mapState } from 'vuex'
import AddAccountModal from '../components/addAccountModal'
import hoverChild from './hoverChild'
import permissionModal from '../components/permissionModal'
import mixDate from '@/mixins/initDate'
import mixGetList from '@/mixins/getList'
// import { getDuration } from '@/utils/duration'

const columns = [
  {
    dataIndex: 'registerTime',
    title: '注册时间',
    key: 'registerTime',
    align: 'left',
    width: 200,
    fixed: true,
    customRender: (registerTime) => {
      return registerTime || '--'
    }
  },
  {
    dataIndex: 'company',
    title: '公司名称',
    key: 'company',
    align: 'left',
    width: 200,
    fixed: true,
    customRender: (company, record, index) => {
      return company || '--'
    }
  },
  {
    dataIndex: 'name',
    title: '账号名称',
    key: 'name',
    align: 'left',
    customRender: (name, record, index) => {
      return name || '--'
    }
  },
  {
    dataIndex: 'roleName',
    title: '账号类型',
    key: 'roleName',
    align: 'left',
    width: 120,
    customRender: (roleName, record, index) => {
      return roleName || '--'
    }
  },
  {
    dataIndex: 'loginId',
    title: '登录邮箱',
    key: 'loginId',
    align: 'left',
    customRender: (loginId) => {
      return loginId || '--'
    }
  },
  {
    dataIndex: 'contact',
    title: '联系人',
    key: 'contact',
    align: 'left',
    width: 120,
    customRender: (contact) => {
      return contact || '--'
    }
  },
  {
    dataIndex: 'createUserName',
    title: '创建者',
    key: 'createUserName',
    align: 'left',
    customRender: (createUserName) => {
      return createUserName || '--'
    }
  },
  {
    dataIndex: 'mobile',
    title: '联系方式',
    key: 'mobile',
    align: 'left',
    customRender: (mobile) => {
      return mobile || '--'
    }
  },
  // {
  //   dataIndex: 'mainApp',
  //   title: '主营应用',
  //   key: 'mainApp',
  //   align: 'left',
  //   customRender: (mainApp) => {
  //     return mainApp || '--'
  //   }
  // },
  {
    dataIndex: 'appResponseVoList',
    title: '关联应用数',
    key: 'appResponseVoList',
    align: 'left',
    scopedSlots: { customRender: 'appResponseVoList' }
  },
  {
    dataIndex: 'sts',
    title: '状态',
    key: 'sts',
    align: 'left',
    scopedSlots: { customRender: 'sts' }
  },

  {
    dataIndex: 'action',
    title: '操作',
    scopedSlots: { customRender: 'action' },
    align: 'left'
  }
]
export default {
  mixins: [mixDate, mixGetList],
  name: 'AccountManage',
  components: { AddAccountModal, hoverChild, permissionModal },
  data () {
    return {
      isAdd: '',
      columns,
      loading: false,
      query: {
        page: 1,
        limit: 10
      },
      total: 0,
      roleList: [
        { name: '管理员', id: '1' },
        { name: '媒体', id: '2' }
      ],
      dataList: [],
      formquery: {},
      transferList: [],
      personVisible: false,
      type: 0,
      rangePickerDate: null, // 日期
      permissionVisible: false,
      accountInfo: {}
    }
  },
  computed: {
    ...mapState({
      role: (state) => state.user.roles[0],
      adminUserId: (state) => state.user.adminUserId,
      mediaAccess: (state) => state.user.mediaAccess
    })
  },
  created () {
    this.getPlatList()
  },
  mounted () {
    this.getsubAccountPager()
  },
  methods: {
    // 跳转到应用页面
    goToApp () {
      this.$router.push({ path: '/media/app' })
    },
    // 不可选时间
    disabledDate (current) {
      // 默认大于今天的时间 小于2021.9
      // return (current && current < this.initDate(getDuration(), 0).start) || current > this.initDate(0, 1).end
    },
    // 时间修改
    ChangeDate (date, dateString) {
      this.rangePickerDate = dateString
      const [dateStart, dateEnd] = dateString
      this.query.dateStart = dateStart
      this.query.dateEnd = dateEnd
    },
    // 获取报表数据
    async getsubAccountPager () {
      this.loading = true
      const res = await accountPager(this.query)
      if (res.code === 200) {
        this.loading = false
        this.dataList = res.data.items || []
        this.total = res.data.total
      }
    },
    // 新增账号
    handleClickAdd (data) {
      this.$router.push({
        path: `/accountInfo/addAccountManage?type=add`,
        query: {
          steps: 1
        }
      })
    },
    // 搜索
    handleSerach () {
      this.query.page = 1
      this.getsubAccountPager()
    },
    changeAccountInfo () {
      this.getsubAccountPager()
    },
    // 修改
    handleClickUpdatePlace (record) {
      this.$router.push({
        path: `/accountInfo/addAccountManage?type=edit`,
        query: {
          steps: 1,
          id: record.id
        }
      })
    },
    // 删除
    handleDelete (record) {
      deleteAccount({
        id: record.id,
        isAdmin: record.isAdmin,
        sts: 'D'
      }).then((resp) => {
        if (resp.code === 200) {
          this.$message.success('删除成功')
          this.handleSerach()
        }
      })
    },
    // 点击绑定按钮
    async handleClickBind (record, type) {
      this.type = type
      this.formquery.id = record.id
      if (type === 1) {
        const res = await getAdminLists()
        if (res.code === 200) {
          res.data.map((item) => {
            item.disabled = record.id === item.id
          })
          this.transferList = res.data
        }
      }
      this.recordObj = record
      // this.recordObj.remarkList = ['jsjjs', 'kkkkk']
      this.personVisible = true
    },
    handleClickPermission (record) {
      this.accountInfo = JSON.parse(JSON.stringify(record))
      this.permissionVisible = true
    },
    // 分页
    onShowSizeChange (current, size) {
      this.query.page = 1
      this.query.limit = size
      this.getsubAccountPager()
    },
    // 点击页码
    handleChangePage (page) {
      this.query.page = page
      this.getsubAccountPager()
    }
  }
}
</script>

<style lang="less" scoped>
.personnel-container {
  margin: 0 10px;
  border-radius: 5px;
  padding: 20px;
  background: #fff;
  .ponsonnel_search {
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    .ant-calendar-picker {
      .ant-calendar-picker-icon {
        display: block;
        color: #b3b3b3;
      }
    }
  }
  .table {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-radius: 5px;
    .pagination {
      margin-top: 20px;
    }
    .action-slot {
      .btn {
        color: @primary-color;
        cursor: pointer;
      }
      .line {
        color: #ccc;
      }
      span {
        margin-right: 10px;
      }
    }
  }
}
::v-deep {
  .ant-modal-wrap .ant-modal .ant-modal-body {
    margin: 30px 0 20px 0;
  }
}
</style>
