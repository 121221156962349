<template>
  <div class="sdk-version-search-container">
    <a-button icon="plus" type="primary" @click="addSDKVisible = true">添加{{ title }}</a-button>
    <div class="search-wrapper">
      <a-select
        class="search_item"
        :allowClear="true"
        placeholder="请选择操作系统"
        v-model="searchquery.os"
        @change="handleSelectOs"
      >
        <a-select-option v-for="item in osList" :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
      </a-select>
      <a-select
        :filter-option="filterOption"
        showSearch
        class="search_item"
        :allowClear="true"
        placeholder="请选择SDK版本"
        v-model="searchquery.version"
        @change="handleAutoSearch"
      >
        <a-select-option v-for="item in versionList" :key="item.id" :value="item.version">{{
          item.version
        }}</a-select-option>
      </a-select>
      <addSDKModal
        :visible="addSDKVisible"
        :sdkInfo="{}"
        :isAdd="true"
        @modalCancel="addSDKVisible = false"
        @changeAppInfo="handleAddSDKModal"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { getPublishedSdkList } from '@/api/system'
import addSDKModal from '../components/addSDKModal'
export default {
  components: { addSDKModal },
  data () {
    return {
      osList: [
        { name: '安卓', id: '1' },
        { name: 'IOS', id: '0' }
      ],
      versionList: [],
      searchquery: {
        os: undefined,
        version: undefined
      },
      addSDKVisible: false
    }
  },
  created () {
    this.getPublishedSdkList()
  },
  computed: {
    ...mapState({
      role: (state) => state.user.roles[0],
      isAdmin: (state) => state.user.isAdmin,
      title: (state) => state.autoweb.title
    })
  },
  methods: {
    filterOption (input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    handleAutoSearch () {
      this.$emit('handleAutoSearch', this.searchquery)
    },
    async handleSelectOs (e) {
      await this.getPublishedSdkList()
      this.handleAutoSearch()
    },
    async getPublishedSdkList () {
      const resp = await getPublishedSdkList({
        os: this.searchquery.os
      })
      this.versionList = resp.data || []
      const hasVersion = this.versionList.findIndex((item) => item.version === this.searchquery.version)
      if (hasVersion === -1) {
        this.searchquery.version = undefined
      }
    },
    handleAddSDKModal () {
      this.addSDKVisible = false
      this.handleAutoSearch()
    }
  }
}
</script>

<style lang="less">
.sdk-version-search-container {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  align-items: center;
  .search-wrapper {
    .search_item {
      width: 220px;
      margin-left: 10px;
      margin-right: 10px;
    }
  }
}
</style>
