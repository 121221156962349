/*
 * @Author: zt zhoutao@ydmob.com
 * @Date: 2024-03-04 17:01:02
 * @LastEditors: zt zhoutao@ydmob.com
 * @LastEditTime: 2024-03-07 15:01:18
 * @FilePath: /mediatom-web/src/components/CreativeForm/GroupRullForm/groupRules.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
export const groupRules = [{
    type: 'time',
    items: [
      {
        label: '地区',
        id: 4,
        showKey: 'areaRuleSts'
      },
      {
        label: '日期',
        id: 5,
        showKey: 'dateRuleSts'
      },
      {
        label: '时间',
        id: 6,
        showKey: 'timeRuleSts'
      }

    ]
  },
  {
    type: 'app',
    items: [
      {
        label: 'SDK版本',
        id: 2,
        showKey: 'sdkRuleSts'
      },
      {
        label: '应用版本',
        id: 10,
        showKey: 'appversionRuleSts'
      },
      {
        label: '应用安装时间',
        id: 13,
        showKey: 'installtimeRuleSts'
      }
    ]
  },
  {
    type: 'equipment',
    items: [{
        label: '渠道',
        id: 1,
        showKey: 'channelRuleSts'
      },
      {
        label: '设备ID',
        id: 3,
        showKey: 'equipmentRuleSts'
      },
      {
        label: '设备品牌',
        id: 7,
        showKey: 'brandRuleSts'
      },
      {
        label: '网络类型',
        id: 8,
        showKey: 'nettypeRuleSts'
      },
      {
        label: '系统版本',
        id: 9,
        showKey: 'sysversionRuleSts'
      },
      {
        label: '设备型号',
        id: 14,
        showKey: 'unittypeRuleSts'
      },
      {
        label: '运营商',
        id: 16,
        showKey: 'operatorRuleSts'
      }
    ]
  },
  {
    type: 'channel',
    items: [
      {
        label: '子渠道',
        id: 11,
        showKey: 'childchannelRuleSts'
      },
      {
        label: '自定义规则',
        id: 12,
        showKey: 'custRuleSts'
      }

    ]
  }
]
