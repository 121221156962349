<template>
  <div class="sdk-manage-container">
    <div class="tabs-wrapper">
      <div
        v-for="(item, inx) in radioArr"
        :key="inx"
        :class="['top_item', current === item.value ? 'act' : '']"
        @click="handleChangeTab(item.value)"
      >
        {{ item.name }}
      </div>
    </div>
    <div class="sdk-manage-wrapper">
      <sdkVersionManage v-if="current === 'sdkversion'" />
      <platVersionManage v-if="current === 'platversion'" />
    </div>
  </div>
</template>

<script>
import sdkVersionManage from './sdkVersionManage'
import platVersionManage from './platVersionManage'
import { mapState } from 'vuex'
export default {
  components: { sdkVersionManage, platVersionManage },
  data () {
    return {
      current: '',
      selectedKeys: '',
      radioArr: []
    }
  },
  created () {
    this.radioArr = [
      { name: `${this.title}版本`, value: 'sdkversion' },
      { name: `广告平台版本`, value: 'platversion' }
    ]
  },
  computed: {
    ...mapState({
      title: (state) => state.autoweb.title
    })
  },
  watch: {
    $route: {
      handler (val) {
        if (val.query.target) {
          this.current = val.query.target
        } else {
          this.$router.push({
            query: {
              target: 'sdkversion'
            }
          })
          this.current = 'sdkversion'
        }
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    handleChangeTab (value) {
      this.$router.push({
        query: {
          target: value
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.sdk-manage-container {
  margin: 0 10px 10px 10px;
  border-radius: 5px;
  background: #fff;
  .tabs-wrapper {
    display: flex;
    padding: 10px 10px 25px 10px;
    border-bottom: 1px solid #EEEEEE;
    margin-bottom: 10px;
    .top_item {
      font-size: 14px;
      color: #333333;
      margin: 8px 8px 0;
      padding: 0 14px;
      border-radius: 5px;
      height: 30px;
      line-height: 30px;
      cursor: pointer;
    }
    .act {
      color: @primary-color;
      background: fade(@primary-color, 10%);
    }
  }
  .sdk-manage-wrapper {
    min-height: 500px;
    padding: 0 10px 10px 10px;
  }
}
</style>
