/*
 * @Author: zt zhoutao@ydmob.com
 * @Date: 2024-02-27 18:52:41
 * @LastEditors: zt zhoutao@ydmob.com
 * @LastEditTime: 2024-04-12 15:39:28
 * @FilePath: /mediatom-web/src/apiForManage/creativeMask.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import request from '@/utils/request'
const baseUrl = '/shield'
// 获取素材审核列表
export function getShieldPager (data) {
  return request({
    url: `${baseUrl}/pager`,
    method: 'post',
    data
  })
}

export function getPlaceInfo (data) {
  return request({
    url: `${baseUrl}/placeList`,
    method: 'post',
    data
  })
}

export function addShieldRule (data) {
  return request({
    url: `${baseUrl}/add`,
    method: 'post',
    data
  })
}

export function changeShieldSts (data) {
  return request({
    url: `${baseUrl}/updateSts`,
    method: 'post',
    data
  })
}

export function getShieldInfo (data) {
  return request({
    url: `${baseUrl}/info`,
    method: 'post',
    data
  })
}

export function updateShieldRule (data) {
  return request({
    url: `${baseUrl}/update`,
    method: 'post',
    data
  })
}

export function getDspSourceInfo (data) {
  return request({
    url: `${baseUrl}/platList`,
    method: 'post',
    data
  })
}
