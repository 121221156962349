/*
 * @Author: zt zhoutao@ydmob.com
 * @Date: 2024-03-04 17:45:42
 * @LastEditors: zt zhoutao@ydmob.com
 * @LastEditTime: 2024-03-05 11:54:14
 * @FilePath: /mediatom-web/src/components/CreativeForm/GroupRuleForm/groupRuleMixins.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { getAreaList, getBrandList } from '@/api/common'
export default {
  data () {
    return {
      netTypeList: ['WIFI', '2G', '3G', '4G', '5G'],
      operatorList: [
        { name: '中国移动', id: '1' },
        { name: '中国联通', id: '2' },
        { name: '中国电信', id: '3' },
        { name: '其他', id: '0' }
      ],
      brandList: [],
      areaList: [],
      options1: [
        {
          value: 0,
          label: '包括'
        }
      ],
      optionsStr2: [
        {
          value: 0,
          label: '包括'
        },
        {
          value: 1,
          label: '不包括'
        }
      ],
      options4: [
        {
          value: 0,
          label: '包括'
        },
        {
          value: 1,
          label: '不包括'
        },
        {
          value: 2,
          label: '>'
        },
        {
          value: 3,
          label: '<'
        }
      ],
      options2Symb: [
        {
          value: 2,
          label: '>'
        },
        {
          value: 3,
          label: '<'
        }
      ]
    }
  },
  methods: {
    async getBrandList () {
      const { data = [] } = await getBrandList()
        data.forEach((item) => {
          item.id = item.name
        })
      this.brandList = Object.freeze(data || [])
    },
    // 获取地区列表
    async getAreaList () {
      const { data = [] } = await getAreaList()
      this.areaList = data
    }
  }
}
